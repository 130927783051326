import React from 'react';
import { makeStyles, shorthands, mergeClasses } from "@griffel/react";
import { Tooltip } from '@/components/tooltip/Tooltip';
import { ButtonProps } from './buttonProps';
import { DashboardLocaleDK } from '../dashboard/locale/DashboardLocaleDK';

const usePrimaryButtonStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        ...shorthands.gap("15px"),

        "@media (max-width: 480px)": {
            flexDirection: "column",
        },
    },

    button: {
        height: "40px",
        minHeight: "40px",
        // backgroundColor: "grey",
        color: "black",
        fontSize: '1rem',
        cursor: 'pointer',
        width: "100%",

        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        ...shorthands.transition(
            "background, color, border, border-color, box-shadow",
            "350ms",
            "ease-in-out",
            "0ms"
        ),
        ...shorthands.border("1px", "solid", "rgb(41, 61, 94)"),
        borderRadius: "5px",
    },
    selected: {
        backgroundColor: `rgb(41, 61, 94)`,
        color: "white",

    }
});

export const PrimaryButton: React.FC<ButtonProps> = ({
    onClick,
    label = DashboardLocaleDK.goBack,
    resolution,
    selectedValue,
    style
}) => {
    const styles = usePrimaryButtonStyles();
    return (
        <Tooltip label={label + " View " + "(" + resolution + ")"} direction='below'>
            <button
                className={mergeClasses(styles.button, selectedValue?.toLowerCase() === label.toLowerCase() ? styles.selected : '')}
                onClick={onClick}
                style={style}
            >
                {label}
            </button>
        </Tooltip>
    );
};