"use client";
import { InputComponentType, InputProps, quickformtokens, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";

import { ButtonsInput, ButtonsProperties } from "../../buttons-input/ButtonsInput";
import { makeStyles } from "@griffel/react";
import { mergeClasses, shorthands } from "@fluentui/react-components";
import { useState } from "react";
import { BasketInputSchema } from "./BasketInputSchema";
 
export const BaskedInput: InputComponentType<ButtonsProperties> = ({  inputType, ...props }) => {
    //CONVERT product to options
    const buttonProps = props as Omit<typeof props, "inputType">;
    
    return (//@ts-ignore
        <ButtonsInput  {...buttonProps} inputType="buttons" />
    )
}

BaskedInput.inputSchema = BasketInputSchema;
registerInputComponent("basket-input", BaskedInput);