import "./slider-input/SliderInput";
import "./checkbox-input/CheckboxInput";
import "./radio-input/RadioInput";
import "./buttons-input/ButtonsInput";
import "./input-components/address-input/AddressInput";
import "./email-input/EmailInput";
import "./phone-input/PhoneInput";
import "./text-input/TextInput";
import "./number-input/NumberInput";
import "./multiline/MultilineInput";
import "./input-components/distance-input/DistanceInput";
//import "../../../GroupOnline.QuotationPlatformManagement/src/components/tooltip/Tooltip";