import { JSONSchema7 } from "json-schema";
export const ProductInputSchema = {
    label: "Product Picker",
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        // Notice here we change the ui component from the generic quickform to our customer specific component -> ../Designer/ButtonsOptionsField
        productId: {
            "ui:field": "ProductLookupField",            
        },
        options: {
            "ui:widget":"hidden"
        }
    },
    schema: {
        type: "object",

        properties: {
            text: {
                title: "Text",
                type: "string"
            },
            productId: {
                type: "string",
            },
            options: {
                type: "object",
            }
        }
    } as JSONSchema7
}