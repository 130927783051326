import React from "react";
import { IconProps } from "./iconProps";

export const ErrorIcon: React.FC<IconProps> = ({ color, size = 100, style, className }) => {
    return (
        <div className={className} style={{ textAlign: 'center', height: size, ...style }}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 104 104"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="32" cy="32" r="30" fill="#FCE9E9" />

                <path
                    d="M32 14C32.6406 14 33.2392 14.3204 33.5623 14.8585L48.5623 40.8585C48.8783 41.3861 48.8388 42.0473 48.4577 42.5267C48.0766 43.0061 47.4552 43.2 46.8125 43.2H17.1875C16.5448 43.2 15.9234 43.0061 15.5423 42.5267C15.1612 42.0473 15.1217 41.3861 15.4377 40.8585L30.4377 14.8585C30.7608 14.3204 31.3594 14 32 14Z"
                    fill="none"
                    stroke="#D63E3E"
                    strokeWidth="3"
                    strokeLinejoin="round"
                />

                <circle cx="32" cy="39" r="2" fill="#D63E3E" />
                <rect x="30" y="22" width="4" height="12" rx="2" ry="2" fill="#D63E3E" />

            </svg>
        </div >
    );
}