import { ungzip } from 'pako';
import { QuotationData, QuotationLine } from '../types/types';

/**
 * Processes compressed data from task entities.
 * @param {any} data - The potentially compressed data object from a task entity.
 * @returns The uncompressed and parsed JSON object or the original data.
 */
function processCompressedData(data: any): any {
    if (data && data.compressed) {
        const binaryString = atob(data.data);
        const binaryArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            binaryArray[i] = binaryString.charCodeAt(i);
        }
        const decompressed = ungzip(binaryArray, { to: "string" });
        return JSON.parse(decompressed);
    }
    return data?.data;
}

/// TODO
// function processLines(lines: any): QuotationLine[] {
//     const result: QuotationLine[] = [];
//     Object.entries(lines).forEach(([key, value]) => {
//         result.push({
//             line: key,
//             product: { name: value.productname, priceamount: value.priceamount, unit: value.unit },
//             quantity: value.quantity,
//             taxamount: value.taxamount,
//             totalpriceamount: value.totalpriceamount
//         });
//     });
//     return result;
// };

/**
 * Processes a task and related data to retrieve structured details.
 * @param {any} task - The task to process.
 * @returns An object containing the task, payload, quick form definition, quotation lines and quotation.
 */
export function processTask(task: any): { task: any; payload: Record<string, any>; quickformDef: any, lines: QuotationLine[], quotation: { totalPriceAmount: number; totalTaxAmount: number; } } {
    const quotation = task?.data?.items[0]?.task.quotationtaskrelations[0]?.quotation;
    const quickformDef = processCompressedData(quotation?.quotationform?.quickformdefinition);
    const payload = processCompressedData(quotation?.quotationpayloads[0]?.payload);
    const lines = quotation?.quotationlines;

    return { task: { ...task?.data?.items[0]?.task }, payload, quickformDef, lines, quotation };
}


/**
 * Processes a task and related data to retrieve structured details.
 * 
 * @param {any} task - The task to process. This includes potentially nested structures
 *                     with compressed data that needs to be decompressed and parsed.
 * @returns {QuotationData} An object containing structured quotation details such as
 *                          the quotation name, price details, customer contact info,
 *                          and line items.
 */
export function processData(task: any): QuotationData {
    
    console.log("QUOTATION__taskPreProcessing", task);
    const { task: processedTask, payload, quickformDef, lines, quotation } = processTask(task);

    console.log("QUOTATION__taskPostProcessing", [processedTask, payload, quickformDef, lines, quotation]);

    const defaultLines = [
        { name: 'Test', price: 100, quantity: 3, unit: 'stk' },
        { name: 'Test', price: 10000, quantity: 1, unit: 'stk' },
        { name: 'Test', price: 10000, quantity: 2, unit: 'stk' }
    ];

    return {
        quotationName: processedTask.name,
        currency: 'DKK',
        name: payload?.name ?? "Test Testersen",
        telephone: payload?.phone ?? "12345678",
        email: payload?.email ?? "test@testersen.dk",
        quotationLines: lines ?? defaultLines,
        totalPrice: quotation?.totalPriceAmount ?? 30300,
        totalTaxAmount: quotation?.totalTaxAmount ?? 0
    };
}