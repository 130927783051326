import React from "react";
import { IconProps } from "./iconProps";

export const MapMarkerIcon: React.FC<IconProps> = ({ color = "green", size = 100, style, className }) => {
    return (
        <div className={className} style={{ textAlign: 'center', height: size, ...style }}>

            <svg width={size}
                height={size}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512">

                <path fill={color} d="M172.3 501.7C27 291 0 269.4 0 192 0 86 86 0 192 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.8-29.9 13.8-39.5 0z" /></svg>
        </div>
    );
};
