import { RegistereRibbonButton, SimpleDialog, useAppInfo, useModelDrivenApp, useModelDrivenViewContext, useRibbon, useSelectionContext } from "@eavfw/apps";
import { runWorkflow } from "@eavfw/utils";
import {
    Button,
    Dialog, DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger
} from "@fluentui/react-components";
import { Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useState } from "react";
import { useRouter } from "next/router";


RegistereRibbonButton("CUSTOM_RIBBON_VIEW_IMPORT", ({ key, ...props }) => {
    const { registerButton } = useRibbon();
    const router = useRouter();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isLoading, setIsLoading] = useState(false);
    const [importFile, setImportFile] = useState<File | null>(null);

    const handleFileChange = (event: any) => {
        let file = event.target.files?.[0]
        if (file) {
            setImportFile(file);
        }
    };

    const upload = async (file: File | null) => {
        console.log("fileToUpload: ", file);
        setIsLoading(true);

        const formData = new FormData()
        if (file !== null) {
            formData.set('data', new Blob([file], { type: file.type }), file.name);
            formData.set('compressed', 'false');
            formData.set('container', 'data');
        }

        const uploadUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/files/upload`
        var response = await fetch(uploadUrl, {
            method: "POST",
            credentials: "include",
            body: formData
        });

        var json = await response.json();

        console.log("json response: ", json);

        let { result, rsp } = await runWorkflow("ImportTemplateWorkflow", "Manual", { importFileId: json?.value?.[0]?.id });
        console.log(result);

        router.reload();
        setIsLoading(false);

    };

    registerButton({
        key: key,
        text: props.text ?? "Run Workflow",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Run Workflow",
        disabled: isLoading,
        onClick: () => {
            toggleHideDialog();
        }
    }, [isLoading]);

    return <Dialog open={!hideDialog}>
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Import from file</DialogTitle>
                <DialogContent>
                    {!importFile && "No file selected..."}
                    {!!importFile && (<div>{"Selected file: " + importFile?.name}</div>)}
                </DialogContent>
                <DialogActions fluid>
                    <input type="file" onChange={handleFileChange} />
                    <Button
                        appearance="primary"
                        disabled={isLoading || !importFile}
                        onClick={() => {
                            upload(importFile);

                        }}>
                        {isLoading ? <Spinner /> : 'Import selected file'}
                    </Button>
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            console.log("file1234: ", importFile);
                            setImportFile(null);
                            console.log("file1234567: ", importFile);
                            toggleHideDialog();
                            setIsLoading(false);
                        }}>
                        {'Close'}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
});
