import React from 'react';
import { makeStyles, shorthands, mergeClasses } from '@griffel/react';
import { BackButton, CustomerCard, CustomerContactInformation, QuotationInvoiceDisplay } from './components';
import { useModelDrivenApp } from '@eavfw/apps';
import { queryEntitySWR } from '@eavfw/manifest';
import { processData } from './utils/dataHelper';

const useLeadCustomerViewStyles = makeStyles({
    containerShadow: {
        ...shorthands.border('1px solid #ccc'),
        ...shorthands.padding('10px'),
        borderRadius: '5px',
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    customerViewItemsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '10px',
        textAlign: 'center',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
        }
    },
    customerViewItem: {
        width: '100%',
        boxSizing: 'border-box',
        '@media (max-width: 1024px)': {
            flex: '1 1 100%',
        }
    },
    oneThirdCol: {
        flex: '1 1 30%',
        maxWidth: '768px',
    },
    twoThirdsCol: {
        flex: '1 1 60%',
    },
    backButtonContainer: {
        display: "block",
        height: "30px"
    },
    notesActivityContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        textAlign: 'center',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
        }
    }
});

type LeadCustomerViewProps = {
    taskId: string;
    setTaskId: (customer: string | null) => void;
}

export const LeadCustomerView: React.FC<LeadCustomerViewProps> = ({ taskId, setTaskId }) => {
    const classes = useLeadCustomerViewStyles();
    const app = useModelDrivenApp();

    const handleGoBackClicked = () => {
        setTaskId(null);
    };

    const task = queryEntitySWR(app.getEntityFromKey("Board Task"), {
        "$filter": `taskid eq ${taskId}`,
        "$select": "id,task",
        // "$expand": "task($expand=quotationtaskrelations($expand=quotation))" 
        "$expand": "task(" +
            "$expand=quotationtaskrelations(" +
                "$expand=quotation(" +
                    "$expand="+
                        "quotationpayloads($expand=payload)," +
                        "quotationform($expand=quickformdefinition)," +
                        "quotationlines" +
                ")" +
            ")" +
        ")"
    });

    if (task.isLoading) return <>Loading..</>;

    const data = processData(task);

    return (
        <div className={classes.containerShadow}>
            {/*<div className={classes.backButtonContainer}>*/}
            {/*    <BackButton*/}
            {/*        onClick={handleGoBackClicked}*/}
            {/*        size={28}*/}
            {/*        color={"rgb(230, 93, 25)"}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className={classes.customerViewItemsContainer}>
                <div id="oneThirdCol" className={mergeClasses(classes.customerViewItem, classes.oneThirdCol, classes.containerShadow)}>
                    <CustomerCard
                        quotationName={data.name}
                        telephoneNumber={data.telephone}
                        currency={data.currency}
                        totalPrice={data.totalPrice}
                    />
                </div>

                <div id="twoThirdsCol" className={mergeClasses(classes.customerViewItem, classes.twoThirdsCol)}>
                    {/* <div className={classes.notesActivityContainer}> */}
                    <div style={{ marginBottom: '5px' }}>
                        <QuotationInvoiceDisplay
                            currency={data.currency}
                            quotationLines={data.quotationLines}
                            totalPriceAmount={data.totalPrice}
                            totalTaxAmount={data.totalTaxAmount}
                        />
                    </div>
                    <CustomerContactInformation
                        email={data.email}
                        name={data.name}
                        phone={data.telephone}
                    />
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};
