import { JSONSchema7 } from "json-schema";
 
import { InputComponentMetadata } from "@eavfw/quickform-core";
import { ButtonsProperties } from "../../buttons-input/ButtonsInput";

export const BasketInputSchema: InputComponentMetadata<ButtonsProperties> = {
    label: "Kurv (Antal/Frekvens)",
    field: {
        type: "select",
        // typeProvider: (a) => a.maxItems === 1 ? "select" : "multiselect",
        listValuesProvider: (a) => Object.entries(a.options ?? {}).map(([okey, o]) => (typeof (o) === "string" ?
            {
                label: o,
                name: okey,
                value: okey,
            } : { label: o.label, name: okey, value: okey, }))
    },
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        productId: { "ui:widget": "hidden" },
        options: {
            "ui:field": "ButtonsOptionsField",
            product: {
                "ui:field": "ProductLookupField",
                "ui:supportProjectReference": true
            }
        },
    },
    schema: {
        type: "object",

        properties: {
            text: {
                title: "Text",
                type: "string"
            },
            productId: {
                type: "string",
            },
            options: {
                type: "object",
                additionalProperties: {
                    "type": "object",
                    required: ["label"],
                    properties: {
                        //key: { "type": "string", title: "Key", description: "The key used for the option, this is also the keyboard key used to select this option" },
                        label: { "type": "string", title: "Label", description: "Valgmulighed der vises til brugeren" },
                        product: { type: "string", title: "Product", description: "Hvilket produkt skal der ligges i kurven" },
                        quantity: { type: "number", title: "Quantity", description: "Hvor mange skal der ligges i kurven" },

                    }
                }
            }
        }
    } as JSONSchema7
}