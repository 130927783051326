import React from 'react';
import { makeStyles } from '@griffel/react';
import { DashboardLocaleDK } from '@/components/dashboard/locale/DashboardLocaleDK';

const useStyles = makeStyles({
    container: {
        padding: '10px',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        border: '1px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontSize: '0.9em',
        color: '#888',
    },
    dealName: {
        fontSize: '1.2em',
        fontWeight: 'bold',
    },
});

type DealNameProps = {
    dealName: string;
};

export const DealName: React.FC<DealNameProps> = ({ dealName }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span className={classes.label}>{DashboardLocaleDK.dealName}</span>
            <span className={classes.dealName}>{dealName}</span>
        </div>
    );
};