import * as React from 'react';
import { makeStyles } from '@griffel/react';
import { TelephoneIcon } from '@/components/icons/TelephoneIcon';
import { EmailIcon } from '@/components/icons/EmailIcon';
import { UserIcon } from '@/components/icons/UserIcon';
import { DashboardLocaleDK } from '@/components/dashboard/locale/DashboardLocaleDK';

const useStyles = makeStyles({
    container: {
        margin: 'auto',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        padding: '10px 20px 20px 20px',
        borderRadius: '8px',
        backgroundColor: 'white',
    },
    header: {
        borderBottom: '2px solid #eee',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
        borderTop: '1px solid #eee',
    },
    icon: {
        marginRight: '5px',
    }
});

type CustomerContactProps = {
    name: string;
    email: string;
    phone: string;
}

export const CustomerContactInformation: React.FC<CustomerContactProps> = ({ name, email, phone }) => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>{DashboardLocaleDK.contactInformation}</h2>
            </div>
            <div className={styles.item}>
                <div style={{ display: "flex" }}>
                    <UserIcon className={styles.icon} size={14} />
                    <span>{DashboardLocaleDK.customerName}</span>
                </div>
                <span>{name}</span>
            </div>

            <div className={styles.item}>
                <div style={{ display: "flex" }}>
                    <EmailIcon className={styles.icon} size={14} />
                    <span>{DashboardLocaleDK.email}</span>
                </div>
                <span>{email}</span>
            </div>

            <div className={styles.item}>
                <div style={{ display: "flex" }}>
                    <TelephoneIcon className={styles.icon} size={14} />
                    <span>{DashboardLocaleDK.telephone}</span>
                </div>
                <span>{phone}</span>
            </div>

        </div>
    );
};
