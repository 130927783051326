"use client";
import React, { useState, useMemo } from "react";
import { InputComponentType, quickformtokens, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";
import { makeStyles, shorthands, mergeClasses } from '@griffel/react';
import { buttonsInputSchema } from "./ButtonsInputSchema";


const useButtonStyles = makeStyles({
    buttonContainer: {
      //  display: "grid",

        

         display: 'flex',
         flexDirection: 'row',
         flexWrap: 'wrap',
        width: '100%',
        ...shorthands.gap("15px"),

        "@media (max-width: 600px)": {
            flexDirection: "row",
            gridTemplateColumns: "1fr"
        },


        ///* Extra small devices (phones, 600px and down) */
        //"@media only screen and (max-width: 600px)": {
        //    flexBasis: "100%",
        //    gridTemplateColumns: "1fr"
        //},

        ///* Small devices (portrait tablets and large phones, 600px and up) */
        //"@media only screen and (min-width: 600px)": {
        //    // flexBasis: "50%",
        //    flexBasis: "calc(50% - 7.5px)",
        //    gridTemplateColumns: 'repeat(auto-fit, minmax(calc(50% - 7.5px), 1fr))'
        //},

        ///* Medium devices (landscape tablets, 768px and up) */
        //"@media only screen and (min-width: 768px)": {
        //    flexBasis: "calc(33.3333% - 10px)",
        //    gridTemplateColumns: 'repeat(auto-fit, minmax(calc(33.3333% - 10px), 1fr))',
             
    
        //},

        ///* Large devices (laptops/desktops, 992px and up) */
        //"@media only screen and (min-width: 992px)": {
        //    // flexBasis: "22%",
        //    flexBasis: "calc(25% - 11.25px)",
        //    gridAutoColumns: "minmax(0, 1fr)",
        //    gridAutoFlow: "column",
        //    gridTemplateColumns:"initial",
        //},
    },
    
    button: {
        height: "55px",
        minHeight: "55px",
        backgroundColor: `${quickformtokens?.surface ?? "grey"}`,
        color: `${quickformtokens?.onSurface ?? "white"}`,
        fontSize: '1rem',
        cursor: 'pointer',
        width: "100%",
        flexGrow: 0,
        flexBasis: "100%",
       
        /* Extra small devices (phones, 600px and down) */
        "@media only screen and (max-width: 600px)": {
            flexBasis: "100%",
           
        },

        /* Small devices (portrait tablets and large phones, 600px and up) */
        "@media only screen and (min-width: 600px)": {
            // flexBasis: "50%",
            flexBasis: "calc(50% - 7.5px)",
            ":nth-child(2n+1):nth-last-child(-n+2)": {
                flexGrow: '1'
            },

            ':nth-child(2n+1):nth-last-child(-n+2) ~ button': {
                flexGrow: '1'
            },
        },

      
        /* Medium devices (landscape tablets, 768px and up) */
        "@media only screen and (min-width: 768px)": {
            flexBasis: "calc(33.3333% - 10px)",
            ":nth-child(3n+1):nth-last-child(-n+3)": {
                flexGrow: '1'
            },

            ':nth-child(3n+1):nth-last-child(-n+3) ~ button': {
                flexGrow: '1'
            },
             
        },
        /* Large devices (laptops/desktops, 992px and up) */
        "@media only screen and (min-width: 992px)": {
            // flexBasis: "22%",
            flexBasis: "calc(25% - 11.25px)",

            ":nth-child(4n+1):nth-last-child(-n+4)": {
                flexGrow: '1'
            },

            ':nth-child(4n+1):nth-last-child(-n+4) ~ button': {
                flexGrow: '1'
            },
            
        },

       

        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        ...shorthands.transition(
            "background, color, border, border-color, box-shadow",
            "350ms",
            "ease-in-out",
            "0ms"
        ),
        ...shorthands.border("1px", "solid", "#dddddd"), //#19959
        borderRadius: "5px",
    },
    selected: {
        backgroundColor: `${quickformtokens?.primary ?? "grey"}`,
        color: `${quickformtokens?.onPrimary ?? "white"}`,

    }
});

export type ButtonsProperties = {
    inputType: "buttons";
    options: {
        [key: string]: {
            key: string;
            label: string;
            order?: number;
        }
    }
    defaultValue?: string;
}

export const ButtonsInput: InputComponentType<ButtonsProperties> = ({ questionModel, options, style }) => {
    const { answerQuestion } = useQuickForm();
    const [selectedValue, setSelectedValue] = useState<string>(questionModel.output);
    const buttonStyles = useButtonStyles();

    const handleChange = (value: string) => {
        setSelectedValue(value);
        answerQuestion(questionModel.logicalName, value);
    };
    const sortedOptions = useMemo(() => Object.entries(options).map(([k, v], index) => ({ key: k, value: v, order: v.order ?? index })).sort((a, b) => a.order - b.order), [options]);
    return (
        <div className={buttonStyles.buttonContainer} style={style}>
            {sortedOptions.map(({ key, value }) => (
                <button
                    key={`button-key-${key}`}
                    onClick={() => handleChange(key)}
                    className={mergeClasses(buttonStyles.button, selectedValue === key ? buttonStyles.selected : '')}
                    id={`${questionModel.logicalName}-${value.key}`}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* {selectedValue === key && <Checkmark style={{ paddingLeft: '10px' }} size={20} color={quickformtokens.onPrimary} />}{value} */}
                        {value.label}
                    </div>
                </button>
            ))}
        </div>
    );
};

ButtonsInput.inputSchema = buttonsInputSchema;
registerInputComponent("buttons", ButtonsInput);