"use client";
import { quickformtokens, useQuickForm } from "@eavfw/quickform-core";
import { useFocusableQuestion } from "@eavfw/quickform-core/src/hooks/useFocusableQuestion";
import { CSSProperties, ChangeEvent, InputHTMLAttributes, useState } from "react";
import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { QuestionModel } from "@eavfw/quickform-core/src/model";
import { IconResolver, IconType } from "../icons/IconResolver";
import { Tooltip } from "../tooltip/Tooltip";
import { ValidationResult } from "@eavfw/quickform-core/src/model/ValidationResult";

import { trace } from "@opentelemetry/api";

const tracer = trace.getTracer("grouponline", "1.0.0");

const useInputTextStyles = makeStyles({
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: 'transparent',
        color: `${quickformtokens.onSurface}`,
        width: '100%',
        ...shorthands.borderTop('none'),
        ...shorthands.borderLeft('none'),
        ...shorthands.borderRight('none'),
        ...shorthands.borderBottom("1px", "solid", quickformtokens.questionPlaceholderColor),

        ':focus': {
            ...shorthands.borderBottom("1px", "solid", `${quickformtokens.primary}`),
            paddingBottom: '8px',
        },
    },
    inputText: {
        color: `${quickformtokens.onSurface}`,
        backgroundColor: 'transparent',
        fontSize: quickformtokens.questionInputFontSize,
        marginTop: '8px',
        paddingBottom: '9px',
        width: '100%',
        ...shorthands.border('none'),

        '@media screen and (max-width: 599px)': {
            fontSize: quickformtokens.questionInputFontSize,
        },

    },

    inputIcon: {
        marginTop: '8px',
        paddingBottom: '9px',
    },
    iconLeft: {
        left: '0',
        paddingRight: '15px'
    },
    iconRight: {
        right: '0',
        paddingLeft: '15px'
    },
    unit: {
        marginTop: '8px',
        paddingBottom: '2px',
    },
    infoIcon: {
        fontSize: '8px',
    },
    infoContainer: {
        position: "absolute",
        top: "-50%",
        right: "0px",
        fontSize: "0.7em",
        '@media screen and (max-width: 599px)': {
            fontSize: "0.5em",
            left: "95%",
        },
    }

});

type BaseInputComponentProps = {
    type: InputHTMLAttributes<HTMLInputElement>["type"],
    questionModel: QuestionModel,
    unit?: string,
    beforeIcon?: IconType;
    afterIcon?: IconType
    style?: CSSProperties
    helperText?: string,
    className?: string,
}

export const BaseInputComponent: React.FC<BaseInputComponentProps> = ({ questionModel, className, style, type, unit, beforeIcon, afterIcon, helperText }) => {

   // const [text, setText] = useState<string>(questionModel!.output);
    const ref = useFocusableQuestion<HTMLInputElement>(questionModel.logicalName);
    const { answerQuestion } = useQuickForm();
    const styles = useInputTextStyles();

    const span = trace.getActiveSpan();

    const resize = () => {
        const input = ref.current;
        if (!input)
            return;

        const oldvalue = input.value;

        if (!oldvalue || oldvalue === '')
            input.value = input.placeholder;

        const isOverflowed = input.scrollWidth > input.clientWidth;
        input.value = oldvalue;
        if (isOverflowed) {
            var style = window.getComputedStyle(input, null).getPropertyValue('font-size');
            input.style.fontSize = (parseFloat(style) - 1) + "px";
            resize();
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
         
       // setText(() => event.target.value);
        answerQuestion(questionModel.logicalName, event.target.value, true);
        resize();
    }

    const handleBlur = () => {

        if (span) {
            span.addEvent("BaseInputComponent:handleBlur");
        }
        answerQuestion(questionModel.logicalName, questionModel!.output, false);
        // Add any additional logic you want to execute on blur
    };

    return (
        <div>
            <div className={mergeClasses(styles.inputContainer, className)} style={style}>
                {beforeIcon &&
                    <IconResolver
                        type={beforeIcon}
                        className={mergeClasses(styles.inputIcon, styles.iconLeft)}
                        size={18}
                        color={quickformtokens.primary}
                    />
                }
                <input
                    style={{ outline: 'none', }}
                    ref={ref}
                    type={type}
                    className={styles.inputText}
                    placeholder={questionModel.placeholder}
                    value={questionModel!.output}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {unit &&
                    <div
                    className={mergeClasses(styles.unit, styles.iconRight)}
                        dangerouslySetInnerHTML={{ __html: `${unit}` }}/>
                }
                {afterIcon &&
                    <IconResolver
                        type={afterIcon}
                        className={mergeClasses(styles.inputIcon, styles.iconRight)}
                        size={18}
                        color={quickformtokens.primary}
                    />
                }
                {helperText &&
                    <div className={styles.infoContainer}>
                        <Tooltip
                            label={helperText}
                            direction="upperLeft"
                        >
                            <IconResolver
                                type={"Info"}
                                className={mergeClasses(styles.inputIcon, styles.iconRight, styles.infoIcon)}
                                size={18}
                                backgroundColor={"white"}
                                color={quickformtokens.primary}
                            />
                        </Tooltip>
                    </div>
                }
            </div>
        </div>
    );
}



