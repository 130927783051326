import { RegisterWorkflowRibbonButton, useSelectionContext } from "@eavfw/apps";



RegisterWorkflowRibbonButton("CUSTOM_RIBBON_COPY_PRODUCT", {
    disabledHook: () => {
        const { selection, selectionDetails } = useSelectionContext();

        return selection.count !== 1;
    }
});