"use client";
import { RegisterControl, useAppInfo, useModelDrivenApp } from "@eavfw/apps";
import { useEffect, useState } from "react";
import { queryEntitySWR } from "@eavfw/manifest";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { QuotationEmbedScriptComponent } from "../QuotationEmbedScriptComponent";

type DemoDisplayTypes = "mobile" | "tablet" | "desktop";
type DemoDisplay = {
    [key in DemoDisplayTypes]: { width: number, height: number, title: string, resolution?: string };
};

const demoDisplays: DemoDisplay = {
    mobile: {
        width: 520,
        height: 800,
        title: "Mobile",
        resolution: "520x800"

    },
    tablet: {
        width: 768,
        height: 1024,
        title: "Tablet",
        resolution: "768x1024"
    },
    desktop: {
        width: 1440,
        height: 1080,
        title: "Desktop",
        resolution: "1440x1080"
    }
};


export const QuickFormSettingsViewPreviewComponent = () => {
    const [currentDemoDisplay, setCurrentDemoDisplay] = useState<DemoDisplayTypes>("tablet");
    const [templateId, setTemplateId] = useState<string>("");
    const { currentRecordId, currentEntityName } = useAppInfo();
    const app = useModelDrivenApp();

    const onDemoDisplayButtonClicked = (demoDisplay: DemoDisplayTypes) => setCurrentDemoDisplay(demoDisplay);


    const quotationTemplate = queryEntitySWR(app.getEntity(currentEntityName),
        {
            "$filter": `id eq ${currentRecordId}`,
            // "$select": "id,task",
            "$expand": "quickformdefinition"
        }
    );

    useEffect(() => {
        if (!quotationTemplate.data) return;
        else {
            console.log("QUOTATION TEMPLATE", quotationTemplate.data.items[0]);
            setTemplateId(quotationTemplate.data.items[0].id);
        }
    }, [quotationTemplate]);

    const onButtonClicked = (value: string) => {
        console.log("SELECTED VALUE", value);
        // setSelectedValue(value);
        onDemoDisplayButtonClicked(value as DemoDisplayTypes)
    };

    return (
        <div>
            <div style={{ display: "flex", gap: "15px", marginBottom: "20px", marginTop: "20px" }}>
                {Object.keys(demoDisplays).map((key) => {
                    return <PrimaryButton
                        key={key}
                        onClick={onButtonClicked.bind(null, key)}
                        label={demoDisplays[key as DemoDisplayTypes].title}
                        resolution={demoDisplays[key as DemoDisplayTypes].resolution}
                        selectedValue={currentDemoDisplay}
                        style={{ paddingRight: "10px", paddingLeft: "10px" }}
                    />
                })}
            </div>
            <div style={{
                width: demoDisplays[currentDemoDisplay].width,
                height: demoDisplays[currentDemoDisplay].height,
                maxWidth: "100%",
                maxHeight: "100%",
                overflow: 'auto'
            }}>
                {templateId !== "" &&
                    <QuotationEmbedScriptComponent showEmbed={false} showLabel={false} />
                }
            </div>
        </div>

    );
}

RegisterControl("QuickFormSettingsViewPreviewComponent", QuickFormSettingsViewPreviewComponent);