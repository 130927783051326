"use client";
import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./AddressInput.module.css";
import { Combobox, Option } from "@fluentui/react-components";

export type DawaLocation = { type: string, tekst: string, caretpos: string, forslagstekst: string, data: any };

type Props = {
    location: string;
    isLoaded: boolean;
    handleLocationSet: (location: string) => void;
}
export function DawaAddressInput({ location, handleLocationSet }: Props) {
    const [text, setText] = useState(location ?? "");
    const [addressInFocus, setAddressInFocus] = useState(false);
    const [addressOptions, setAddressOptions] = useState<DawaLocation[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const numAddresses = 10;

    const getAddressesAsync = async (address: string) => {
        setIsLoading(true);
        const url = `https://api.dataforsyningen.dk/autocomplete?q=${address}&fuzzy=&per_side=${numAddresses}`;
        const res = await fetch(url);
        const data = await res.json();
        const mappedData: DawaLocation[] = data.map((item: DawaLocation) => item);

        return mappedData;
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setText(text);
        setAddressInFocus(text.length > 0);

        const mappedData = await getAddressesAsync(text).then((d) => { setIsLoading(false); return d });
        setAddressOptions(mappedData);
    };

    const handleAddressSelected = async (dawaLocation: DawaLocation) => {
        setText(dawaLocation.tekst);
        handleLocationSet(dawaLocation.tekst);

        const mappedData = await getAddressesAsync(dawaLocation.tekst).then((d) => { setIsLoading(false); return d });
        setAddressOptions(mappedData);

        // If type is adresse, address specifications are exhausted therefor force close the address options else reopen
        if (dawaLocation.type === "adresse")
            setAddressInFocus(false);
        else {
            setAddressInFocus(true);
        }
    };

    const handleInputBlur = () => {
        if (!isLoading) {
            setAddressInFocus(false);
        }
    };

    const handleInputFocus = () => {
        setAddressInFocus(true);
    };
    return (
        <Combobox placeholder={"Vælg en adresse"}
            onChange={handleChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            positioning={"below"}
            value={text}
            open={addressInFocus}>
            {addressInFocus && (
                addressOptions.map((o, i) => (
                    <Option key={i} onClick={() => { handleAddressSelected(o) }}>
                        {o.tekst}
                    </Option>
                ))
            )}
        </Combobox>
    );
};