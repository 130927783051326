"use client";
import React from 'react';
import { LeadCustomerView } from './lead-customer-view/LeadCustomerView';
import { KanbanBoardV3 } from '@eavfw/task-management/src/components/kanban-board-v3/KanbanBoardV3';
import { useRouter } from 'next/router';

type LeadKanbanBoardProps = {
    boardId: string;
};

export const LeadKanbanBoard: React.FC<LeadKanbanBoardProps> = ({ boardId }) => {
    
    const router = useRouter();
    console.log(router);
    const taskId = router.asPath.split('#')?.[1]?.split('/')?.[1];
    if (boardId === "" || typeof boardId === "undefined") return (<div>Board ID is empty</div>);

    return (
        <>
            {
                taskId ?
                    <LeadCustomerView
                        taskId={taskId}
                        setTaskId={(taskid) => { router.push({ hash: 'tasks/' + taskid }) }}
                    />
                    :
                    <KanbanBoardV3
                        boardId={boardId}
                        onItemClicked={(taskid) => { router.push({ hash: 'tasks/'+ taskid }) }}
                    />
            }
        </>
    );
};