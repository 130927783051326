import React from "react";
import { IconProps } from "./iconProps";

export const InfoIcon: React.FC<IconProps> = ({ style, color = "red", size = 100 }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <svg
                width={size}
                height={size}
                style={style}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
                <circle
                    cx="256"
                    cy="256"
                    r="248"
                    stroke={color}
                    strokeWidth="16"
                    fill="none"
                />
                <circle
                    cx="256"
                    cy="160"
                    r="32"
                    fill={color}
                />
                <path
                    d="M216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                    fill={color}
                />
            </svg>
        </div>
    );
}
