import { DashboardLocale } from "./DashboardLocale";

export const DashboardLocaleDK: DashboardLocale = {
    navbarTitle: "Dashboard",
    statistics: "Statistik",
    selectView: "Vælg visning:",
    dealSummary: "Overblik",
    callTo: "Ring til",
    list: "Liste",
    board: "Tavle",
    delete: "Slet",
    product: "Produkt",
    unit: "Enhed",
    deals: "Aftaler",
    noData: "Ingen data",
    noBoardId: "Ingen tavle-id",
    contactInformation: "Kontaktinformation",
    email: "Email",
    quotationInvoiceSummary: "Tilbuds- og fakturaoversigt",
    dealName: "Aftalenavn",
    goBack: "Gå tilbage",
    statisticsNotImplemented: "Statistik er ikke implementeret endnu",
    customerCardTitle: "Kunde",
    quotation: "Tilbud",
    telephone: "Telefon",
    loading: "Indlæser",
    price: "Pris",
    actions: "Handlinger",
    won: "Vundet",
    lost: "Tabt",
    total: "Total",
    quantity: "Antal",
    customerName: "Navn",
    currency: "Valuta",
    tax: "Moms",
}