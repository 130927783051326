import React from "react";
import { ErrorModal } from "../ErrorBoundary/ErrorModal";

type ErrorBoundaryProps = {
    children: React.ReactNode;
};

type ErrorBoundaryState = {
    hasError: boolean;
    errorMessage: Error | undefined;
    showErrorMessage: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false, errorMessage: undefined, showErrorMessage: false }
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, errorMessage: error, showErrorMessage: false }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log({ error, errorInfo })
    }

    handleClose = () => {
        this.setState({ hasError: false, errorMessage: undefined, showErrorMessage: false });
    };

    handleShowErrorMessage = () => {
        this.setState({ showErrorMessage: !this.state.showErrorMessage });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorModal
                    errorMessage={this.state.errorMessage?.message}
                    onClose={this.handleClose}
                />
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary