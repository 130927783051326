import { RegistereControl, useAppInfo, useModelDrivenApp } from "@eavfw/apps";
import { useSectionStyles } from "@eavfw/apps/src/Styles/SectionStyles.styles";
import { queryEntitySWR } from "@eavfw/manifest";
import { makeStyles, mergeStyles } from "@fluentui/react";
import { ODataBuilder } from "@eavfw/query";
import { Button, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, useArrowNavigationGroup, useFocusableGroup } from "@fluentui/react-components";

import Form from "@rjsf/fluentui-rc";
import validator from '@rjsf/validator-ajv8';

import {

    Dialog, DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from "@fluentui/react-components";

import {
    AddRegular,
    FolderRegular,
    EditRegular,
    OpenRegular,
    DocumentRegular,
    PeopleRegular,
    DocumentPdfRegular,
    VideoRegular,
    DeleteRegular,
} from "@fluentui/react-icons";
import { useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { useEAVForm } from "@eavfw/forms";
import { gzip, ungzip } from "pako";
import { Field } from "@rjsf/utils";
import { useRouter } from "next/router";


export const LineGeneratorFields: { [key: string]: Field } = {

}

export const registerLineGeneratorField = (name: string, widget: Field) => {
    LineGeneratorFields[name] = widget;
}

const useQuotationLineManagerStyles = makeStyles({
    root: {
        width: "100%"
    }
});
type EAVDoc = {
    data: any
}
type QuotationForm = {

}
type QuotationLineGenerator = {
    name: string
    configurationschema: string,
    configurationhash: string
}
type LineGeneratorAssosiation = {
    id: string;
    configuration: EAVDoc,
    generator: QuotationLineGenerator,
    quotationform: QuotationForm,

}
type tableItem = {
    id: string;
    name: string
    configurationschema: any
    configuration: any
}
const columns = [
    { columnKey: "name", label: "Name" },
    //  { columnKey: "author", label: "Author" },
    //  { columnKey: "lastUpdated", label: "Last updated" },
    { columnKey: "actions", label: "Actions" },
];

export const QuotationLineManager = () => {

    const eavstyles = useSectionStyles();
    const styles = useQuotationLineManagerStyles();
    const app = useModelDrivenApp();
    const { currentRecordId, currentEntityName } = useAppInfo();
    const router = useRouter();

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [selectedRow, setRjsfData] = useState<tableItem>();
  //  const [configurationData, setSonfigurationData] = useState<any>(null);

  const isTemplate = currentEntityName === "quotationformtemplate";
    var builder = new ODataBuilder<LineGeneratorAssosiation>()
        .expand("generator", g => g.select("name", "configurationhash", "configurationschema"))
        .expand("configuration", c => c.select("data"))
        .filter(isTemplate ? `QuotationFormTemplateId eq ${currentRecordId}`  :`quotationformid eq ${currentRecordId}`)


    const { data } = queryEntitySWR<LineGeneratorAssosiation>(currentEntityName == "quotationform" ?
        app.getEntityFromKey("Quotation Line Generator Assosiation") :
        app.getEntityFromKey("Quotation Line Template Generator Assosiation"), builder.build());

    const [_, { onChange }] = useEAVForm(s => ({}));


    console.log(data);
    const items = useMemo(() => {

        var test = (data?.items ?? [])
            .map((item) => (
                {
                    id: item.id,
                    name: item.generator?.name,
                    configurationschema: JSON.parse(item.generator.configurationschema),
                    configuration: item?.configuration?.data ? JSON.parse( ungzip(new Uint8Array(atob(item.configuration.data).split("").map(function (c) {
                        return c.charCodeAt(0)
                    })), { to: "string" }) as string) : {}
                }
            ) as tableItem);
        return test;
    }, [data]);
    const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
    const focusableGroupAttr = useFocusableGroup({
        tabBehavior: "limited-trap-focus",
    });

    return (
        <>
            <Dialog open={!!selectedRow}>
                <DialogSurface>
                    <DialogBody>
                         
                        <DialogContent>
                            {selectedRow && <Form tagName="div"


                                validator={validator}
                                fields={LineGeneratorFields}

                                {...selectedRow.configurationschema}
                                formData={selectedRow.configuration}
                                onChange={(rsjfchange, b) => {
                                    console.log("change", [rsjfchange, b]);
                                    selectedRow.configuration = rsjfchange.formData;

                                  
                                }}
                            >
                                <>
                                </>
                            </Form>
                            }
                        </DialogContent>
                        <DialogActions fluid>
                            <Button
                                

                                onClick={() => setRjsfData(undefined)}>
                            Cancel
                            </Button>
                            <Button
                                appearance="primary"

                                onClick={() => {
                                    onChange((old) => {
                                        if (selectedRow) {
                                            if (!("quotationlinegeneratorassosiations" in old)) {
                                                old["quotationlinegeneratorassosiations"] = [];
                                            }
                                            let mutatedItem = old["quotationlinegeneratorassosiations"].find((i: any) => i.id == selectedRow.id);
                                            if (!mutatedItem) {
                                                mutatedItem = { id: selectedRow.id };
                                                old["quotationlinegeneratorassosiations"].push(mutatedItem);
                                            }
                                            mutatedItem.configuration ??= {};

                                            mutatedItem.configuration.compressed = true;
                                            mutatedItem.configuration.data = btoa(String.fromCharCode.apply(null, Array.from(gzip(JSON.stringify(selectedRow.configuration)))));

                                           
                                        }
                                    });

                                    setRjsfData(undefined);
                                }
                                }>
                                Add
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <div className={mergeStyles(eavstyles.section, styles.root)}>
                {router.query?.debug && <pre>{JSON.stringify(data ?? {}, null, 4)}</pre>}
                <div>
                    <Button icon={<AddRegular />} aria-label="Add" >Add</Button>
                </div>
                <Table
                    {...keyboardNavAttr}
                    role="grid"
                    aria-label="Table with grid keyboard navigation"
                >
                    <TableHeader>
                        <TableRow>

                            {columns.map((column) => (
                                <TableHeaderCell key={column.columnKey}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}


                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell tabIndex={0} role="gridcell">
                                    <TableCellLayout>
                                        {item.name}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                                    <TableCellLayout>
                                        <Button icon={<EditRegular />} aria-label="Edit" onClick={() => {

                                            setRjsfData(item);
                                        }} />
                                        <Button icon={<DeleteRegular />} aria-label="Delete" />
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div >
        </>
    );

}

export const QuotationLineConfiguration = () => {
    const [{ configurationschema, configuration }, { onChange }] = useEAVForm((state) => ({
        configuration: state.formValues.configuration?.data ? JSON.parse(ungzip(new Uint8Array(atob(state.formValues.configuration?.data).split("").map(function (c) {
            return c.charCodeAt(0)
        })), { to: "string" }) as string) : {},
        configurationschema: state.formValues?.generator?.configurationschema ? JSON.parse(state.formValues.generator.configurationschema):null
    }));

    if (!configurationschema)
        return null;

    return (

        <Form tagName="div"


            validator={validator}
            fields={LineGeneratorFields}

            {...configurationschema}
            formData={configuration}
            onChange={(rsjfchange, b) => {
                console.log("change", [rsjfchange, b]);
              //  selectedRow.configuration = rsjfchange.formData;

                onChange(old => {
                    console.log("old", old);
                    old.configuration = {
                        ...old.configuration ?? {},
                        compressed: true,
                        data: btoa(String.fromCharCode.apply(null, Array.from(gzip(JSON.stringify(rsjfchange.formData)))))
                    };
                
                    
                });

            }}
        >
            <>
            </>
        </Form>
    )
}
RegistereControl("QuotationLineConfiguration", QuotationLineConfiguration);
RegistereControl("QuotationLineManager", QuotationLineManager);