
import { Field, Textarea } from "@fluentui/react-components";
import { FieldProps, ObjectFieldTemplatePropertyType, ObjectFieldTemplateProps, WidgetProps, getUiOptions } from "@rjsf/utils";
import { RJSFSchema } from "@rjsf/utils/lib/types";

import dynamic from 'next/dynamic';
const BundledEditor = dynamic(
    () => import('./bundle').then(module => module.BundledEditor),
    { ssr: false },
);

import { registerInputControlDesignerField } from "@eavfw/quickform-designer";
import { useRef } from "react";
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { type Editor as TinyMCEEditor } from 'tinymce'




export const RichTextField: React.FC<FieldProps> = ({ uiSchema = {}, schema: rawSchema, required, formData, registry, title, name, ...props }) => {

    const { fields, formContext, schemaUtils, translateString, globalUiOptions } = registry;
    const schema = schemaUtils.retrieveSchema(rawSchema, formData);

   
    const uiOptions = getUiOptions(uiSchema, globalUiOptions);
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const templateTitle = uiOptions.title ?? schema.title ?? title ?? name;
    const description = uiOptions.description ?? schema.description;
    return (<>
        <Field label={uiOptions.label === false ? '' : templateTitle} required={required} hint={description} >
            
            <BundledEditor
                onInit={(_evt, editor) => editorRef.current = editor}
                onEditorChange={(content) => props.onChange(content)}
                value={formData}
               
                init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                        'searchreplace', 'table', 'wordcount'
                    ],
                    //formats: {
                    //    bold: [
                    //        { inline: 'strong', remove: 'all', styles: { fontWeight: '900' } },
                    //        { inline: 'span', styles: { fontWeight: '900' } },
                    //        { inline: 'b', remove: 'all' }
                    //    ],
                    //},
                    style_formats: [
                        {
                            title: 'Prisside', items: [
                                {
                                    title: 'Prisside - Headline',
                                    inline: 'span', 
                                    styles: { 'font-size': '35px', 'font-weight': 'normal' }
                                },
                                {
                                    title: 'Prisside - Pris',
                                    inline: 'span',
                                    styles: { 'font-size': '40px', 'font-weight': '900' }
                                },
                                {
                                    title: 'Prisside - Moms',
                                    inline: 'span',
                                    styles: { 'font-size': '35px', 'font-weight': 'normal' }
                                },
                                {
                                    title: 'Prisside - Subline',
                                    inline: 'span',
                                    styles: { 'font-size': '25px', 'font-weight': 'bold' }
                                },
                                {
                                    title: 'Prisside - Brødtekst',
                                    inline: 'span',
                                    styles: { 'font-size': '20px', 'font-weight': 'normal' }
                                }
                            ]
                        }
                    ],
                    toolbar: 'undo redo | styles | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | anchor |' +
                        'removeformat | help',
                    content_style: 'strong { font-weight: 900}',
                   // style_formats_autohide: true,
                  //  style_formats_merge: true
                }}
            />
        </Field>

    </>);
};
registerInputControlDesignerField("QF_EndingSlideTextField", RichTextField);
registerInputControlDesignerField("QF_EndingSlideParagraphField", RichTextField);



