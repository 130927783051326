import React from 'react';
import { makeStyles, mergeClasses } from '@griffel/react';
import { DealName, ActionButton, DeleteButton } from './index';
import { formatCurrency } from '../utils/formatCurrency';
import { TelephoneIcon } from '@/components/icons/TelephoneIcon';
import { DashboardLocaleDK } from '@/components/dashboard/locale/DashboardLocaleDK';
// import { NoteIcon } from '@/components/icons/NoteIcon';

const useCustomerCardStyles = makeStyles({
    header: {
        display: "flex",
        borderBottom: '2px solid #eee',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    deleteButton: {
        justifyContent: 'flex-end',
        display: 'flex',
        marginBottom: '10px',
        fontSize: '1em',
        fontWeight: 'bold',
    },
    dealName: {
        fontSize: '1em',
        fontWeight: 'bold',
        color: '#333',
    },
    dealButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '30px 0',
    },
    button: {
        padding: '10px 10px',
        color: 'white',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        borderRadius: '5px',
        flex: 1,
        border: 'none',
        fontSize: '1em',
        opacity: 0.9,
    },
    won: {
        backgroundColor: '#4CAF50',
        marginRight: '5px',
    },
    lost: {
        marginLeft: '5px',
        backgroundColor: '#f44336',
    },
    price: {
        fontSize: '2em',
        margin: '10px 0',
        textAlign: 'center',
        color: 'gray',
        marginBottom: '20px',
        marginTop: '20px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'center',
        padding: '30px 0',
        borderTop: '1px solid #ddd',
    }

});

type CustomerCardProps = {
    quotationName: string;
    telephoneNumber: string;
    currency: string;
    totalPrice: number;
}

export const CustomerCard: React.FC<CustomerCardProps> = ({ quotationName, telephoneNumber, totalPrice, currency }) => {

    const classes = useCustomerCardStyles();

    const handleOnDeleteClicked = () => { window?.alert('Delete not implemented yet!') };
    const handleCall = () => {
        const cleanNumber = telephoneNumber.replace(/[^0-9+]/g, '');
        window.location.href = `tel:${cleanNumber}`;
    };
    const handleWon = () => { window?.alert(`WON not implemented yet`); };
    const handleLost = () => { window?.alert(`LOST not implemented yet`) };
    // const handleNewNote = () => { window?.alert(`Not implemented yet.`) };

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{ flex: "auto" }}>{DashboardLocaleDK.dealSummary}</h2>
                {/*<DeleteButton className={classes.deleteButton} onClick={handleOnDeleteClicked} size={20} />*/}
            </div>
            <DealName dealName={quotationName} />

            <div className={classes.dealButtons}>
                <button className={mergeClasses(classes.button, classes.won)} onClick={handleWon}>{DashboardLocaleDK.won}</button>
                <button className={mergeClasses(classes.button, classes.lost)} onClick={handleLost}>{DashboardLocaleDK.lost}</button>
            </div>

            <div className={classes.price}>
                <span>{formatCurrency(totalPrice, currency)}</span>
            </div>

            <div className={classes.actions}>
                <ActionButton onClick={handleCall} label={`${DashboardLocaleDK.callTo} ${telephoneNumber}`}>
                    <TelephoneIcon color='white' size={20} />
                </ActionButton>
                {/* 
                <ActionButton onClick={handleNewNote} label="New note">
                    <NoteIcon color="white" size={20} />
                </ActionButton> */}
            </div>
        </div>
    );
};