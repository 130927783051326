import { JSONSchema7 } from "json-schema";

export const distanceInputSchema = {
    label: "Distance",
    uiSchema: {
        "ui:order": ["componentType", "apiKey", "distanceProductId", "hourlyProductId", "startProductId", "options"],
        paragraph: {
            "ui:widget": "textarea"
        },
        distanceProductId: {
            "ui:field": "ProductLookupField",            
        },
        hourlyProductId: {
            "ui:field": "ProductLookupField",
        },
        startProductId: {
            "ui:field": "ProductLookupField",
        },
        options: {
            "ui:field": "GoogleDistanceField",
        },
    },
    schema: {
        type: "object",
        dependencies: {
            componentType: {
                oneOf: [
                    {
                        "properties": {
                            "componentType": {
                                "enum": [
                                    "Dawa"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "componentType": {
                                "enum": [
                                    "Google"
                                ]
                            },
                            apiKey: {
                                title: "Api key",
                                type: "string"
                            },
                        },
                        "required": [
                            "apiKey"
                        ]
                    },
                ]
            }
        },
        required: ["componentType"],
        properties: {
            componentType: {
                title: "API Provider",
                enum: [
                    "Google",
                    "Dawa",
                ],
                // @ts-ignore
                "enumNames": [
                    "Google",
                    "Dawa",
                ]
            },
           
            distanceProductId: {
                title: "Product for distance",
                type: "string",
            },
            hourlyProductId: {
                title: "Product for hourly rate",
                type: "string",
            },
            startProductId: {
                title: "Starting Fee",
                type: "string",
            },

            options: {
                type: "object",
                additionalProperties: {
                    "type": "object",
                    required: ["location"],
                    properties: {
                        key: { "type": "string", title: "Key", description: "Key to find specific location" },
                        title: { "type": "string", title: "Title", description: "The title displayed above a location" },
                        location: { "type": "object", title: "Location", description: "A location to be set either here or by the end user" },
                        isUserInput: { "type": "boolean", title: "isUserInput", description: "Let the end user determine the location" },
                    }
                }
            }, 
        } 
    } as JSONSchema7
}
