"use client";
import { InputComponentType, quickformtokens, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";
import { useLoadScript } from "@react-google-maps/api";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import { makeStyles, shorthands } from '@griffel/react';
import { addressInputSchema } from "./AddressInputSchema";

type Library = 'places';
const libraries: Library[] = ['places'];

export const useAddressStyles = makeStyles({
    inputText: {
        backgroundColor: 'transparent',
        border: 'none',
        ...shorthands.borderBottom("1px", "solid", quickformtokens.primary),
        fontSize: quickformtokens.questionInputFontSize,
        color: quickformtokens.onSurface,
        marginTop: '8px',
        paddingBottom: '9px',
        width: '100%',
        ...shorthands.transition(
            "color,opacity",
            "1s",
            "ease",
            "0ms"
        ),

        ':focus-visible': {
            ...shorthands.borderBottom("2px", "solid", quickformtokens.primary),
            paddingBottom: '8px',
            outline: 'none',
            '::placeholder': {
                color: quickformtokens.onSurface,
                opacity: 1,
            },
        },

        '::placeholder': {
            color: quickformtokens.questionPlaceholderColor,
            opacity: quickformtokens.mediumEmphasisOpacity,
            ...shorthands.transition(
                "color",
                "1s"
            ),
        },

        '@media screen and (max-width: 599px)': {
            fontSize: quickformtokens.multilineTextMobileFontSize,
            marginTop: '32px',
        },
    },
    pacContainer: {
        zIndex: 2147483647,
    },
});

export type AddressProperties = {
    inputType: "address";
    apiKey: string;
}

export const AddressInput: InputComponentType<AddressProperties> = ({ questionModel, apiKey /*= "AIzaSyDOHoFEw5x9np0ZMKFyvU_sgO4bYpE2OWE"*/ }) => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: apiKey, libraries });
    const [text, setText] = useState<string>(questionModel!.output);
    const autocompleteRef = useRef<google.maps.places.Autocomplete>();
    const inputRef = useRef<HTMLInputElement>(null);
    const { answerQuestion } = useQuickForm();
    const styles = useAddressStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
        answerQuestion(questionModel.logicalName, event.target.value, true);
    };



    useEffect(() => {
        const handlePlaceSelected = () => {
            const place = autocompleteRef.current?.getPlace();
            if (place && place.formatted_address) {
                setText(place.formatted_address);
            }
        };

        if (isLoaded && inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, { types: ["address"] });
            autocompleteRef.current.addListener("place_changed", handlePlaceSelected);
        }
        return () => {
            if (autocompleteRef.current) {
                google.maps.event.clearInstanceListeners(autocompleteRef.current);
            }
        };
    }, [isLoaded]);

    if (isLoaded) {
        return (
            <div>
                <input
                    ref={inputRef}
                    type="text"
                    className={styles.inputText}
                    placeholder={questionModel.placeholder}
                    value={text}
                    onChange={handleChange}
                />
            </div>
        );
    } else {
        return (<div>Loading...</div>);
    }

};

AddressInput.inputSchema = addressInputSchema;
registerInputComponent("address", AddressInput);