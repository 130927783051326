import React, { PropsWithChildren } from 'react';
import { makeStyles, shorthands } from "@griffel/react";
import { tokens } from '@fluentui/react-components';

const useTooltipStyles = makeStyles({
    tooltipContainer: {
        position: 'relative',
        display: 'inline-block',
        '&:hover .tooltip': {
            visibility: 'visible',
            opacity: 1,
        }
    },
    tooltip: {
        ...shorthands.padding("0.285714rem", "1.143rem"),
        ...shorthands.margin("0.285714rem"),
        borderRadius: "4px",
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border("1px", "solid", tokens.colorTransparentStroke),
        boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.14) 0px 2px 4px;",
        visibility: 'hidden',
        opacity: 0,
        textAlign: 'center',
        position: 'absolute',
        zIndex: 1,
        transition: 'opacity 0.3s',
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    tooltipAbove: {
        bottom: '115%',
        left: '50%',
        transform: 'translateX(-50%)',
        '&::after': {
            content: '',
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            ...shorthands.borderWidth('5px'),
            ...shorthands.borderStyle('solid'),
            ...shorthands.borderColor(`transparent transparent ${tokens.colorNeutralBackground1} transparent`),
        }
    },
    tooltipBelow: {
        top: '115%',
        left: '50%',
        transform: 'translateX(-50%)',
        '&::after': {
            content: '',
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            ...shorthands.borderWidth('5px'),
            ...shorthands.borderStyle('solid'),
            ...shorthands.borderColor(`transparent transparent ${tokens.colorNeutralBackground1} transparent`),
        }
    },
    tooltipLeft: {
        right: '115%',
        top: '50%',
        transform: 'translateY(-50%)',
        '&::after': {
            content: '',
            position: 'absolute',
            left: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            ...shorthands.borderWidth('5px'),
            ...shorthands.borderStyle('solid'),
            ...shorthands.borderColor(`transparent transparent ${tokens.colorNeutralBackground1} transparent`),
        }
    },
    tooltipRight: {
        left: '115%',
        top: '50%',
        transform: 'translateY(-50%)',
        '&::after': {
            content: '',
            position: 'absolute',
            right: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            ...shorthands.borderWidth('5px'),
            ...shorthands.borderStyle('solid'),
            ...shorthands.borderColor(`transparent transparent ${tokens.colorNeutralBackground1} transparent`),
        }
    }
});

type TooltipProps = {
    label: string;
    direction?: 'above' | 'below' | 'left' | 'right';
    childrenClassname?: string;
}

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({ label, direction = 'above', children, childrenClassname }) => {
    const styles = useTooltipStyles();
    const directionClass = {
        above: styles.tooltipAbove,
        below: styles.tooltipBelow,
        left: styles.tooltipLeft,
        right: styles.tooltipRight
    }[direction];

    return (
        <div className={styles.tooltipContainer}>
            <div className={`${styles.tooltip} ${directionClass} tooltip`}>
                {label}
            </div>
            <div className={childrenClassname ?? ""}>
                {children}
            </div>
        </div>
    );
};