"use client";
import { sliderInputSchema } from "./SliderInputSchema";
import React, { ChangeEvent, useState } from "react";
import { InputComponentType, quickformtokens, registerInputComponent, registerInputTypeValidator, useQuickForm } from "@eavfw/quickform-core";
import { makeStyles, shorthands } from '@griffel/react';
// import { validateSlider } from "./SliderInputValidator";

 
export type SliderProperties = {
    inputType: "slider";
    min: number;
    max: number;
    step: number;
    defaultValue?: number;
    unit: string;
    sliderProductId?: string;
}

// const pulseKeyframes = {
//     '0%': { transform: 'scale(2)' },
//     '50%': { transform: 'scale(3)' },
//     '100%': { transform: 'scale(2)' },
// };

const useSliderInputStyles = makeStyles({
    tail: {
        backgroundColor: quickformtokens.primary,
        height: "10px",
        position: 'absolute',
        top: '4px',
        left: '0',
        borderRadius:'4px'
    },
    rangeSlider: {
        width: '100%',
        marginTop: '60px',
        position: 'relative',
        lineHeight: '16px'
    },
    rangeSliderRange: {
        WebkitAppearance: 'none',
       
        width: '100%',
        height: '10px',
        backgroundColor: quickformtokens.surface,
        ...shorthands.borderRadius('5px'),
        ...shorthands.outline('none'),
        ...shorthands.padding(0),
        ...shorthands.margin(0),

        // Range handle (circle)
        '::-webkit-slider-thumb': {
            appearance: 'none',
             
            width: '25px',
            height: '25px',
            cursor: 'pointer',
            backgroundColor: quickformtokens.primary,
            ...shorthands.borderRadius('50%'),
            ...shorthands.transition("backgroundColor", "0.15s", "ease-in-out"),
            //  ...shorthands.animation("pulse", " 2s", " infinite", " backwards"),
            ':hover': {
                transform: 'scale(1.2)',
            },
            ':active': {
                transform: 'scale(1.2)',
            },
             
            // animationName: pulseKeyframes,
            // animationDuration: '2s',
            // animationIterationCount: 'infinite',
            // animationDirection: 'normal',
            // animationFillMode: 'backwards',

        },
        //'::-webkit-slider-runnable-track': { 
        //       height:"10px",
        //    overflow: 'hidden',
        //      color: quickformtokens.primary,
        //      marginTop: '-1px',
        //}
        // '::MozRangeTrack': {
        //     backgroundColor: 'var(--surface)',
        //     ...shorthands.border(0),
        // },
        // '::MozFocusInner': { ...shorthands.border(0) },
        // '::MozFocusOuter': { ...shorthands.border(0) },
        // '::WebkitSliderThumb': {
        //     boxShadow: '0 0 0 3px var(--white), 0 0 0 6px var(--hover)',
        // }
    },

    rangeLabel: {
        display: 'inline-block',
        position: 'relative',
        width: '60px',
        color: 'var(--on-primary)',
        lineHeight: '20px',
        textAlign: 'center',
        ...shorthands.borderRadius('5px'),
        backgroundColor: 'var(--primary)',
        ...shorthands.padding('5px 10px'),
        marginLeft: '8px',
    },
    rangeValuesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        ...shorthands.padding('10px')
    },
    result: {
        fontSize: '20px',
        minHeight: '30px',
        display: 'flex',
        fontWeight: 'bold',
        marginTop: '10px'
    },
});

export const SliderInput: InputComponentType<SliderProperties> = (
    {
        questionModel,
        max = 100,
        min = 0,
        step = 1,
        defaultValue = 0,
        unit = "m2"
    }
) => {
    const styles = useSliderInputStyles();
    const { answerQuestion } = useQuickForm();
    const [value, setValue] = useState<number>(questionModel.output || (defaultValue || min));

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        setValue(newValue);
        answerQuestion(questionModel.logicalName, newValue, true)
    }

    const calculatePosition = (value: number, min: number, max: number): number => (value - min) / (max - min) * 100;
    
    const labelStyle: React.CSSProperties = {
        position: 'absolute',
        left: `calc(${calculatePosition(Number(value), min, max)}% - ${Number(value) / max * 30}px)`,
        top: '-40px',
        transform: 'translateX(-40%)'
    };
    const tailStyle: React.CSSProperties = {
      
        width:`${calculatePosition(Number(value), min, max)}%`
    };

    return (
        
            <div className={styles.rangeSlider}>
                <div className={styles.rangeLabel} style={labelStyle}
                    dangerouslySetInnerHTML={{ __html: `${value} ${unit}` }} />
                <div className={styles.tail} style={tailStyle}/>
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={handleChange}
                    className={styles.rangeSliderRange}
                />
                <div className={styles.rangeValuesContainer}>
                    <div dangerouslySetInnerHTML={{ __html: `${min} ${unit}` }} />
                    <div dangerouslySetInnerHTML={{ __html: `${max} ${unit}` }} />
                </div>
            </div>

        
    );
};

SliderInput.inputSchema = sliderInputSchema;
registerInputComponent("slider", SliderInput);
// registerInputTypeValidator("slider", validateSlider);