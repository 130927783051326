import { JSONSchema7 } from "json-schema";

export const sliderInputSchema = {
    label: "Slider",
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        sliderProductId: {
            "ui:field": "ProductLookupField",
            "ui:supportProjectReference": true
        },
        unit: {
            "ui:field": "UnitLookupField",
        }
    },
    schema: {
        type: "object",
        properties: {
            sliderProductId: {
                title: "Product for slider",
                type: "string",
            },
            min: {
                title: "Minimum Value",
                type: "number"
            },
            max: {
                title: "Maximum Value",
                type: "number"
            },
            step: {
                title: "Step",
                type: "number"
            },
            defaultValue: {
                title: "Default",
                type: "number"
            },
            unit:{
                title: "Unit",
                type: "string"
            },
        },
        required: ["min", "max", "step"]
    } as JSONSchema7
}
