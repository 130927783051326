import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import React, { useMemo, useState } from "react";
import { ErrorIcon } from '../icons/ErrorIcon';
import { useModelDrivenApp } from "@eavfw/apps";

type ErrorModalProps = {
    errorMessage?: string;
    onClose: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({ errorMessage, onClose }) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const app = useModelDrivenApp();
    const errorBody = useMemo(() => app.getLocalization("ErrorBoundary__HideErrorMessage") ?? "Beklager, noget gik galt. Forsøg venligst igen.", [app]);
    const errorTitle = useMemo(() => app.getLocalization("ErrorBoundary__Title") ?? "Ups, der skete en fejl!", [app]);

    const handleToggleErrorMessage = () => {
        setShowErrorMessage(!showErrorMessage);
    };

    return (
        <Dialog open={true} modalType={"alert"}>
            <DialogSurface>
                <div style={{ position: "absolute", top: "22px", right: "0px" }}>
                    <ErrorIcon />
                </div>
                <DialogBody>
                    <DialogTitle style={{ paddingTop: "6px", fontSize: "1.5rem" }}>
                        {errorTitle}
                    </DialogTitle>
                    <DialogContent style={{ marginTop: "16px" }}>
                        <p>{errorBody}</p>
                        <p
                            style={{
                                paddingTop: "16px",
                                fontStyle: "italic",
                                opacity: "0.6",
                                cursor: "pointer"
                            }}
                            onClick={handleToggleErrorMessage}
                        >
                            {showErrorMessage ? "Skjul fejlbeskeden" : "Se fejlbeskeden"}
                        </p>
                        {showErrorMessage && (
                            <div
                                style={{
                                    marginTop: "4px",
                                    padding: "8px 16px",
                                    borderRadius: "4px",
                                    backgroundColor: "#f3f2f1"
                                }}
                            >
                                {errorMessage}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance="primary"
                            style={{
                                color: "#D63E3E",
                                borderColor: "#D63E3E",
                                backgroundColor: "#FCE9E9",
                                marginTop: "16px"
                            }}
                            onClick={onClose}
                        >
                            Luk
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};