"use client"
import { InputComponentType, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";
import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { distanceInputSchema } from "./DistanceInputSchema";
import { GoogleAddressInput } from "./Helper/GoogleAddressInput";
import { DawaAddressInput } from "./Helper/DawaAddressInput";

type Library = 'places';
const libraries: Library[] = ['places'];

export type Location = { key: string, title?: string, location?: string, isUserInput: boolean };
export type LocationAnswer = { [key: string]: Location };
export type AddressProperties = {
    inputType: "distance";
    apiKey: string;
    componentType: string;
    options: {
        [key: string]: {
            key: string;
            title?: string;
            location?: string;
            isUserInput: boolean;
        }
    }
}

export const DistanceInput: InputComponentType<AddressProperties> = ({ questionModel, apiKey, componentType, options }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });
    const [answer, setAnswer] = useState<LocationAnswer>(options);
    useEffect(() => {
        const assignKeys = () => {
            const entries = Object.entries(options);
            entries.forEach(([key, value]) => {
                value.key = key;
            });
            setAnswer(Object.fromEntries(entries));
        }
        assignKeys();
    }, [options])

    let autocompleteRef = useRef<google.maps.places.Autocomplete>();
    const inputRef = useRef<HTMLInputElement>(null);

    const { answerQuestion } = useQuickForm();

    const handleLocationSet = (location: Location) => {
        const tempAnswers = { ...answer, [location.key]: { ...location } };
        setAnswer(tempAnswers);
        answerQuestion(questionModel.logicalName, tempAnswers, true);
    };

    useEffect(() => {
        if (isLoaded && inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(
                inputRef.current,
                { types: ["address"] }
            );
        }

        return () => {
            if (autocompleteRef.current) {
                google.maps.event.clearInstanceListeners(autocompleteRef.current);
            }
        };
    }, [isLoaded]);

    const getTitle = (index: number) => {
        if (index === 0) return "Start";
        if (index === Object.values(options).length - 1) return "Slut";
        return "Via";
    }

    return (
        <div>
            {isLoaded ? (
                <div>
                    {
                        Object.values(options).map((o, i) => (
                            <div key={i}>
                                {o.isUserInput &&
                                    <>
                                        {componentType === "Google"
                                            ? <GoogleAddressInput key={i} title={o.title ?? getTitle(i)} location={o} isLoaded={isLoaded} handleLocationSet={handleLocationSet} />
                                            : <DawaAddressInput key={i} title={o.title ?? getTitle(i)} location={o} isLoaded={isLoaded} handleLocationSet={handleLocationSet} />
                                        }
                                    </>
                                }
                            </div>
                        ))
                    }
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

DistanceInput.inputSchema = distanceInputSchema;
registerInputComponent("distance", DistanceInput);