"use client";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAddressStyles } from "../../address-input/AddressInput";

export type Location = { key: string, title?: string, location?: string, isUserInput: boolean };

type Props = {
    title: string;
    location: Location;
    isLoaded: boolean;
    handleLocationSet: (location: Location) => void;
}

export function GoogleAddressInput({ title, location, isLoaded, handleLocationSet }: Props) {
    const styles = useAddressStyles();
    const [text, setText] = useState(location.location ?? "");

    let autocompleteRef = useRef<google.maps.places.Autocomplete>();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    useEffect(() => {
        if (isLoaded && inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(
                inputRef.current,
                { types: ["address"] }
            );
            const handlePlaceSelected = () => {
                const place = autocompleteRef.current?.getPlace();

                if (place) {
                    const address = place.formatted_address;
                    setText(address ?? "");
                    handleLocationSet({ ...location, location: address });
                }
            };

            autocompleteRef.current.addListener("place_changed", handlePlaceSelected);
        }

        return () => {
            if (autocompleteRef.current) {
                google.maps.event.clearInstanceListeners(autocompleteRef.current);
            }
        };
    }, [isLoaded, handleLocationSet, location]);

    return (
        <div>
            <>{title}</>
            <input
                ref={inputRef}
                type="text"
                className={styles.inputText}
                value={text}
                onChange={handleChange}
                disabled={!location.isUserInput}
            />
        </div>
    );
};