"use client";
import { InputComponentType, InputProps, registerInputComponent } from "@eavfw/quickform-core";
import { ProductInputSchema } from "./ProductInputSchema";
import { ButtonsInput, ButtonsProperties } from "../../buttons-input/ButtonsInput";
import { ImageGrid } from "../product-collection-input/ProductCollectionInput";
//The options part is inserted by the backend upon save from the product id
export type ProductInputProps = {
    inputType: "product-input";
    productid: string;   
    options: {
        [key: string]: {
            key: string;
            label: string;
            imageUrl?: string;
            imageHash?: string;
        }
    }
} & Omit<ButtonsProperties, "inputType">;



export const ProductInput: InputComponentType<ProductInputProps> = ({ productid, inputType, ...props }) => {
    //CONVERT product to options
    const buttonProps = props as Omit<typeof props, "inputType">;

    const useImages = Object.values(props.options).some(x => x.imageUrl);
    console.log("ProductInput", [props, useImages]);


    if (useImages) {//@ts-ignore
        return <ImageGrid  {...buttonProps} inputType="image-grid" />
    }

    return (//@ts-ignore
        <ButtonsInput  {...buttonProps} inputType="buttons" />
    )
}

ProductInput.inputSchema = ProductInputSchema;
registerInputComponent("product-input", ProductInput);