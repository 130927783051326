import * as React from 'react';
import { makeStyles, mergeClasses } from '@griffel/react';
import { formatCurrency } from '../utils/formatCurrency';
import { DashboardLocaleDK } from '@/components/dashboard/locale/DashboardLocaleDK';
import { QuotationLine } from '../types/types';


interface QuotationInvoiceProps {
    totalPriceAmount: number;
    totalTaxAmount: number;
    currency: string;
    quotationLines: QuotationLine[];
}

const useQuotationInvoiceStyles = makeStyles({
    container: {
        margin: 'auto',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        padding: '10px 20px 20px 20px',
        borderRadius: '8px',
        backgroundColor: 'white',
    },
    header: {
        borderBottom: '2px solid #eee',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        padding: '8px',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #eee',
    },
    td: {
        padding: '8px',
        borderBottom: '1px solid #eee',
        textAlign: 'left'
    },
    rightText: {
        textAlign: 'right',
    },
    leftText: {
        textAlign: 'left',
    },
    centerText: {
        textAlign: 'center',
    },
    total: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
    },
    tax: {
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
        fontSize: '1em',
    }
});

export const QuotationInvoiceDisplay: React.FC<QuotationInvoiceProps> = ({ totalPriceAmount, currency, quotationLines }) => {
    const styles = useQuotationInvoiceStyles();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>{DashboardLocaleDK.quotationInvoiceSummary}</h2>
            </div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={mergeClasses(styles.th, styles.leftText)}>{DashboardLocaleDK.product}</th>
                        <th className={mergeClasses(styles.th, styles.centerText)}>{DashboardLocaleDK.unit}</th>
                        <th className={mergeClasses(styles.th, styles.centerText)}>{DashboardLocaleDK.quantity}</th>
                        <th className={mergeClasses(styles.th, styles.centerText)}>{DashboardLocaleDK.tax}</th>
                        <th className={mergeClasses(styles.th, styles.rightText)}>{DashboardLocaleDK.price}</th>
                    </tr>
                </thead>
                <tbody>
                    {quotationLines.map((line, index) => (
                        <tr key={index}>
                            <td className={styles.td}>{line.line}</td>
                            <td className={mergeClasses(styles.td, styles.centerText)}>{line.product?.unit}</td>
                            <td className={mergeClasses(styles.td, styles.centerText)}>{line.quantity}</td>
                            <td className={mergeClasses(styles.td, styles.centerText)}>{formatCurrency(line.taxamount, currency)}</td>
                            <td className={mergeClasses(styles.td, styles.rightText)}>{formatCurrency(line.product?.priceamount * line.quantity, currency)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={styles.tax}>
                <span>{DashboardLocaleDK.tax}</span>
                <span>{formatCurrency(totalPriceAmount * 0.2, currency)}</span>
            </div>
            <div className={styles.total}>
                <span>{DashboardLocaleDK.total}</span>
                <span>{formatCurrency(totalPriceAmount, currency)}</span>
            </div>

        </div>
    );
};