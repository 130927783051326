import { JSONSchema7 } from "json-schema";

export const addressInputSchema = {
    label: "Address",
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        }
    },
    schema: {
        type: "object",
        properties: {
            apiKey: {
                title: "Api key",
                type: "string"
            },
            required: ["apiKey"]
        } 
    } as JSONSchema7
}
