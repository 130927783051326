import React from 'react';
import { makeStyles, shorthands } from "@griffel/react";
import { DashboardLocaleDK } from '../locale/DashboardLocaleDK';

const useLeadNavbarStyles = makeStyles({
    navbarContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '10px',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
        }
    },
    addButton: {
        fontSize: '16px',
        color: 'green',
        backgroundColor: 'transparent',
        ...shorthands.border('none'),
        cursor: 'pointer',
        marginRight: '5px'
    },
    linkStyle: {
        marginRight: '20px',
        cursor: 'pointer',
        color: 'blue',
        ...shorthands.textDecoration('underline'),
    },
    navbarItem: {
        display: 'flex',
        alignItems: 'center'
    }
});

type NavbarProps = {
    boardId: string;
    setBoardId: (boardId: string) => void;
    options: Record<string, string>[];
};

export const LeadNavbar: React.FC<NavbarProps> = ({ boardId, setBoardId, options }) => {
    const classes = useLeadNavbarStyles();

    // const onStatisticsClicked = () => window?.alert(DashboardLocaleDK.statisticsNotImplemented);
    const handleBoardIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => setBoardId(event.target.value as string);

    return (
        <div className={classes.navbarContainer}>
            <div className={classes.navbarItem}>
                <h1>{DashboardLocaleDK.navbarTitle}</h1>
                {/* <button onClick={onStatisticsClicked} className={classes.addButton}>+</button> */}
            </div>
            {/* <div className={classes.navbarItem}>
                <p onClick={onStatisticsClicked} className={classes.linkStyle}>{DashboardLocaleDK.statistics}</p>
            </div> */}
            <div className={classes.navbarItem}>
                <label htmlFor="viewSelector" style={{ marginRight: '10px' }}>{DashboardLocaleDK.selectView}</label>
                <select id="viewSelector" value={boardId} onChange={handleBoardIdChange}>
                    {options.map(option => (
                        <option key={option.key} value={option.value}>{option.key}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};