import { useAppInfo, useModelDrivenApp } from "@eavfw/apps";
import { registerLineGeneratorField } from "../QuotationLineManager";
import { IRecord, queryEntitySWR } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { Combobox, ComboboxProps, useComboboxFilter, Field } from "@fluentui/react-components";
import { useId, useMemo, useState } from "react";
import { Field as RJSFField } from "@rjsf/utils";
import { registerInputControlDesignerField } from "@eavfw/quickform-designer";


type unit = {
    id: string,
    value: string,
} & IRecord


export const UnitLookupField: RJSFField = ({ onChange, formData, schema: { title } }) => {

    console.log("UnitLookupField", { formData });
    const app = useModelDrivenApp();
    const productQuery = new ODataBuilder<unit>()
        .build();

    const { data } = queryEntitySWR<unit>(
        app.getEntityFromKey("Unit"), productQuery);
    const options = useMemo(() => {
        return data?.items.map(i => ({
            children: i.value,
            value: i.value,
            
        }))??[];
    }, [data?.items]);
    const [query, setQuery] = useState<string>();

    const children = useComboboxFilter(query??"", options, {
        noOptionsMessage: "No units match your search.",
        optionToText: (option) => option.children
    });

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
        onChange(data.optionValue);
        setQuery(data.optionText ?? "");
    };

    const comboId = useId();

    return (<Field label={title}>
        <Combobox
            onOptionSelect={onOptionSelect}
            aria-labelledby={comboId}
            placeholder={'Select a unit'}
            defaultSelectedOptions={[formData]}
            defaultValue={options.find(x => x.value === formData)?.children}
            onChange={(ev) => setQuery(ev.target.value)}
          
            value={query ?? options.find(x => x.value === formData)?.children}
        >
            {children}
        </Combobox>
    </Field>)
}

registerLineGeneratorField("UnitLookupField", UnitLookupField);
registerInputControlDesignerField("UnitLookupField", UnitLookupField);