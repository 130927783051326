import { registerInputControlDesignerField } from "@eavfw/quickform-designer"
import { Checkbox, Divider, Field, Input, Switch, SwitchProps } from "@fluentui/react-components"
import { FieldProps, StrictRJSFSchema, UiSchema } from "@rjsf/utils"
import { PropsWithChildren, useMemo } from "react"
import { JSONSchema7 } from "json-schema";

type SubmitFieldsProps = {
    schema?: StrictRJSFSchema,
    uiSchema?: {
        name?: UiSchema,
        phone?: UiSchema,
        email?: UiSchema,
        [key: string]: UiSchema | undefined
    }
}
type SubmitFieldComponentProps = {
    label: string;
    isRequired: boolean;
    propName: string; //keyof (Required<SubmitFieldsProps>["uiSchema"])
    onSwitchChange: (prop: keyof (Required<SubmitFieldsProps>["uiSchema"]), checked: boolean) => void
    onSetRequired: (prop: keyof (Required<SubmitFieldsProps>["uiSchema"]), required: boolean) => void
    formData: SubmitFieldsProps | undefined

}
const SubmitFieldComponent: React.FC<SubmitFieldComponentProps> = ({ isRequired, onSetRequired,formData,propName, onSwitchChange, label }) => {
    const text = getTitle(formData, propName);
    const placeholder = getPlaceholder(formData, propName);
    const isEnabled = formData?.uiSchema?.[propName]?.["ui:widget"] !== "hidden";

    return (<>
        <Divider alignContent="end">
            <Switch style={{ textAlign: 'left' }} labelPosition="before" label={label} checked={isEnabled} onChange={(e, d) => onSwitchChange(propName, d.checked)} />
        </Divider>

        {isEnabled && <>
            <Field label="Text">
                <Input value={ text}  />
            </Field>
            <Checkbox
                checked={isRequired}
                onChange={(e, d) => onSetRequired(propName, d.checked===true)}
                label="Skal udfyldes"
            />
            
            <Field label="Placeholder">
                <Input value={placeholder} />
            </Field>
            <Divider/>
        </>

        }
    </>)
}
function getTitle(formData: SubmitFieldsProps | undefined, propName: string) {
    let schema = formData?.schema;

    if (!schema)
        return "";

    let props = schema.properties;

    if (!props)
        return "";

    let propschema = props[propName];
    if (typeof propschema === "boolean")
        return "";

    return propschema.title;

}
function getPlaceholder(formData: SubmitFieldsProps | undefined, propName: string) {
    return formData?.uiSchema?.name?.["ui:placeholder"]
}
export const SubmitFields: React.FC<PropsWithChildren<FieldProps<SubmitFieldsProps>>> = ({ uiSchema = {}, schema: rawSchema, required, formData, registry, title, name, onChange, ...props }) => {

    console.log("SubmitFields", formData);
     
    const onSwitchChange = (prop: keyof (Required<SubmitFieldsProps>["uiSchema"]), checked: boolean) => {
        formData ??= {};
        const schema = formData.schema = { ...formData?.schema ?? {} };
        schema.properties ??= {};
        schema.properties[prop] = {
            ...(schema.properties[prop] as JSONSchema7) ?? { type: "string" },
            type: "string",
            
        }
        schema.required = [...schema.required ?? []].filter(x => x !== prop);
        if (checked)
            schema.required.push(prop as string);
        onChange({
            ...formData,
            uiSchema: {
                ...formData?.uiSchema ?? {},
                [prop]: {
                    ...formData?.uiSchema?.[prop] ?? {} , 'ui:widget': checked ? undefined : 'hidden' }
            }
        });
    }

    const onSetRequired = (prop: keyof (Required<SubmitFieldsProps>["uiSchema"]), checked: boolean) => {
        let _formData = { ...formData };
        const schema = _formData.schema = { ..._formData?.schema ?? {} };
        
        const old = schema.required?.slice() ?? [];
        schema.properties = { ...schema.properties ?? {} }
        schema.required = [...schema.required ?? []].filter(x => x !== prop);
        if (checked)
            schema.required.push(prop as string);
        console.log("onSetRequired", [prop, checked, schema.required, old]);
        onChange(_formData);
    }

    return <>
        {Object.entries(formData?.schema?.properties ?? {})
            .map(([prop, propschema]) =>
                <SubmitFieldComponent key={prop}
                    isRequired={formData?.schema?.required?.includes(prop) ?? false}
                    formData={formData}
                    propName={prop}
                    label={(propschema as JSONSchema7).title as string}
                    onSwitchChange={onSwitchChange}
                    onSetRequired={onSetRequired}                />)}
        
        
 

    </>
}

registerInputControlDesignerField<SubmitFieldsProps>("QFSubmitField", SubmitFields);