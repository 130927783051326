import { createTheme } from "@fluentui/react";
import { RegisterFeature } from "@eavfw/apps";
//import { Theme, BrandVariants, createLightTheme, createDarkTheme } from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";

 /**
  * Primary: #ff691d
  * Text Color: #293d5e
  * Background: #ffffff
  */
const goorange_default = createTheme({
    palette: {
        themePrimary: '#ff691d',
        themeLighterAlt: '#fff9f6',
        themeLighter: '#ffe7db',
        themeLight: '#ffd2bb',
        themeTertiary: '#ffa477',
        themeSecondary: '#ff7a37',
        themeDarkAlt: '#e65d19',
        themeDark: '#c24f15',
        themeDarker: '#8f3a10',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#acb9cf',
        neutralSecondary: '#697e9f',
        neutralPrimaryAlt: '#3a4f72',
        neutralPrimary: '#293d5e',
        neutralDark: '#202f48',
        black: '#172335',
        white: '#ffffff',
    }
});
/**
 * Primary: #293d5e
 * Text Color: #ffffff
 * Background: #ff691d
 */
const topBarTheme = createTheme({
    palette: {
        themePrimary: '#ff691d',
        themeLighterAlt: '#0a0401',
        themeLighter: '#291104',
        themeLight: '#4d1f08',
        themeTertiary: '#993e11',
        themeSecondary: '#e05b19',
        themeDarkAlt: '#ff7733',
        themeDark: '#ff8c53',
        themeDarker: '#ffaa80',
        neutralLighterAlt: '#283c5c',
        neutralLighter: '#283b5a',
        neutralLight: '#263957',
        neutralQuaternaryAlt: '#233551',
        neutralQuaternary: '#22324d',
        neutralTertiaryAlt: '#21304a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#293d5e',
    }
});

//const myNewTheme: BrandVariants = {
//    10: "#020304",
//    20: "#131720",
//    30: "#1C2537",
//    40: "#22314A",
//    50: "#293D5E",
//    60: "#384A69",
//    70: "#475674",
//    80: "#566480",
//    90: "#65718B",
//    100: "#747F97",
//    110: "#848DA2",
//    120: "#939BAE",
//    130: "#A3AABA",
//    140: "#B3B8C6",
//    150: "#C3C7D2",
//    160: "#D3D7DF"
//};

//const lightTheme: Theme = {
//    ...createLightTheme(myNewTheme),
//};

//const darkTheme: Theme = {
//    ...createDarkTheme(myNewTheme),
//};


//darkTheme.colorBrandForeground1 = myNewTheme[110];
//darkTheme.colorBrandForeground2 = myNewTheme[120];

export default goorange_default;

RegisterFeature("defaultTheme", goorange_default);
RegisterFeature("topBarTheme", topBarTheme);

let v9T = createV9Theme(goorange_default);


RegisterFeature("defaultV2Theme", v9T);
RegisterFeature("topBarV2Theme", createV9Theme(topBarTheme));
 