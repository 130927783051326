"use client";
import React, { useState } from "react";
import { makeStyles, shorthands } from "@griffel/react";
import { radioInputSchema } from "./RadioInputSchema";
import {
    InputComponentType,
    registerInputComponent,
    useQuickForm,
    quickformtokens
} from "@eavfw/quickform-core";

export type RadioProperties = {
    inputType: "radio";
    options: {
        [key: string]: string;
    }
    direction?: "horizontal" | "vertical";
}

const useRadioInputStyles = makeStyles({
    radioContainer: {
        display: "flex",
        ...shorthands.margin("10px 0"),
        boxSizing: "border-box"
    },
    radioDiv: {
        display: "flex",
        alignItems: "center"
    },
    radioInput: {
        /* This hides the default implementation of <input type="radio" /> */
        appearance: "none",
        WebkitAppearance: "none",
        backgroundColor: "transparent",
        ...shorthands.margin(0),

        /* This is the custom styling application - kinda confusing it has to be done this way >.< */
        ...shorthands.borderRadius("50%"),
        ...shorthands.border("0.11em", "solid", `${quickformtokens.primary}`),
        color: `${quickformtokens.primary}`,
        height: "1.15em",
        width: "1.15em",
        marginRight: '10px',
        cursor: "pointer",
        display: "grid",
        ...shorthands.gridArea("center"),

        ":before": {
            content: "",
            width: "0.65em",
            height: "0.65em",
            ...shorthands.borderRadius("50%"),
            opacity: 0,
            backgroundColor: "CanvasText"
        },

        // ":focus": {
        //     ...shorthands.outline("max(2px, 0.15em)", "solid", `${quickformtokens.primary}`),
        //     outlineOffset: "max(2px, 0.15em)"
        // },

        ":checked": {
            opacity: 1,
            boxShadow: `inset 1em 1em ${quickformtokens.primary}`,
            ...shorthands.outline("max(2px, 0.15em)", "solid", `${quickformtokens.primary}`),
            outlineOffset: "max(2px, 0.15em)"
        }
    },
    label: {
        fontSize: quickformtokens.questionParagraphFontSize
    }

});

export const RadioInput: InputComponentType<RadioProperties> = ({ questionModel, options, direction, }) => {
    const { answerQuestion } = useQuickForm();
    const [selectedValue, setSelectedValue] = useState<string>(questionModel.output);
    const styles = useRadioInputStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        answerQuestion(questionModel.logicalName, event.target.value)
    };

    return (
        <div
            className={styles.radioContainer}
            style={{
                flexDirection: direction && direction === 'horizontal' ? 'row' : 'column'
            }}
        >
            {Object.entries(options).map(([key, value]) => (
                <div
                    key={key}
                    className={styles.radioDiv}
                    style={{
                        marginRight: `${direction && direction === 'horizontal' ? '20px' : ''}`,
                        marginTop: `${direction && direction === 'vertical' ? '20px' : ''}`
                    }}
                >
                    <input
                        type="radio"
                        id={`${questionModel.logicalName}-${key}`}
                        name={questionModel.logicalName}
                        value={value}
                        checked={selectedValue === value}
                        onChange={handleChange}
                        className={styles.radioInput}
                    />
                    <label
                        htmlFor={`${questionModel.logicalName}-${key}`}
                        className={styles.label}
                    >
                        {value}
                    </label>
                </div>
            ))}
        </div>
    );
};

RadioInput.inputSchema = radioInputSchema;
registerInputComponent("radio", RadioInput);
