import { FormRender, RegisterControl, RibbonBar, RibbonContextProvider, RibbonHost, StateTabProvider, useEAVApp, useModelDrivenApp, useSectionStyles } from "@eavfw/apps"
import { useEAVForm } from "@eavfw/forms";
import { IRecord, PolyLookupType, TypeFormDefinition, deleteRecordSWR, queryEntitySWR } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { capitalize } from "@eavfw/utils";
import { ICommandBarItemProps } from "@fluentui/react";
import { FlatTreeItemProps, HeadlessFlatTreeItemProps, TreeSelectionValue, mergeClasses, useHeadlessFlatTree_unstable } from "@fluentui/react-components";


import {
    FlatTree,
    FlatTreeItem,
    TreeItemLayout,
    TreeItemValue,
    Spinner,
    makeStyles,
    TreeItemOpenChangeData,
    TreeItemOpenChangeEvent,
} from "@fluentui/react-components";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useStyles = makeStyles({
    screenReadersOnly: {
        position: "absolute",
        width: "1px",
        height: "1px",
        margin: "-1",
        overflow: "hidden",
        clip: "rect(0,0,0,0)",
        whiteSpace: "nowrap",
    },
});


type SubtreeProps = {
    productOptionId: string;
    // treeItemProps: FlatTreeItemProps;
    value: TreeItemValue;
    label: string;
    onSelect: any
    onDataLoading?(): void;
    onDataLoaded?(): void;
};
type ProductOptionValue = {
    $type: string,
    id: string,
    name: string
}
type ProductOption = {
    $type:string,
    id: string,
    name: string,
    productoptionvalues: ProductOptionValue[]
}
type ProductOptionTemplateProductReference = {
    $type: string,
    id: string,
    productoptionid: string,
    productoption: ProductOption,
    productid: string,
}


const Subtree: React.FC<SubtreeProps> = ({
    //  treeItemProps,
    onDataLoaded,
    onDataLoading,
    value,
    label,
    onSelect,
    productOptionId
}) => {
    const [open, setOpen] = useState(false);
    const [{ model }] = useEAVApp();
    // useQuery here is just a helper to simulate async data fetching
    // you can use any other async data fetching library like react-query, swr, etc.
    const { data, isLoading } = queryEntitySWR<ProductOptionValue>(model.getEntityFromKey("Product Option Value"),
        new ODataBuilder<ProductOptionValue>().filter(`productoptionid eq ${productOptionId}`).build(), open)

    // we need to focus the first item when the subtree is opened
    const firstItemRef = useRef<HTMLDivElement>(null);

    const handleOpenChange = useCallback(
        (e: TreeItemOpenChangeEvent, data: TreeItemOpenChangeData) => {
            setOpen(data.open);
        },
        [setOpen]
    );

    //useEffect(() => {
    //    if (open && state === "idle") {
    //        onDataLoading?.();
    //        query(async () => {
    //            // mockFetch is just a helper to simulate an API endpoint.
    //            // you probably will be using some custom API endpoint here.
    //            const json = await mockFetch(value.toString());
    //            return json.results.map<Entity>((entity) => ({
    //                value: `${value}/${entity.name}`,
    //                name: entity.name,
    //            }));
    //        });
    //    }
    //}, [open, onDataLoading, query, state, value]);

    useEffect(() => {
        if (open && data && !isLoading) {
            onDataLoaded?.();
            firstItemRef.current?.focus();
        }
    }, [open, isLoading, onDataLoaded]);

    return (
        <>
            <FlatTreeItem
                value={value}
                aria-level={1}
                aria-setsize={3}
                aria-posinset={1}
                itemType="branch"
                open={open}
                onOpenChange={handleOpenChange}
            >
                <TreeItemLayout
                    expandIcon={open && isLoading ? <Spinner size="tiny" /> : undefined}
                >
                    {label?.toString()}
                </TreeItemLayout>
            </FlatTreeItem>
            {open &&
                data?.items?.map((item, index) => (
                    <FlatTreeItem
                        key={item.id}
                        ref={index === 0 ? firstItemRef : null}
                        parentValue={value}
                        value={`${item.$type}:${item.id}`}
                        aria-level={2}
                        aria-setsize={data.items.length}
                        aria-posinset={index + 1}
                        itemType="leaf" onClick={e => onSelect(item)}
                    >
                        <TreeItemLayout>{item.name}</TreeItemLayout>
                    </FlatTreeItem>
                ))}
        </>
    );
};



const useStackStyles = makeStyles({
    tree: {
        minWidth: "200px"
    },
    stack: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
        '&.horizontal': {
            flexDirection: 'row',
        },
        '&.vertical': {
            flexDirection: 'column',
        },
        '&.fill': {
            width: '100%',
        },
        '&.grow': {
            flexGrow: '1',
        }
    },

})

export const ProductOptionsEditor = () => {

    const [checkedItems, setCheckedItems] = useState<Array<[TreeItemValue, TreeSelectionValue]>>([]);

    const [{ formValues }, { onChange }] = useEAVForm(c => ({ formValues: c.formValues }));
    const [{ model }] = useEAVApp();
    const entity = model.getEntityFromKey("Product Option");
    const optionValueEntity = model.getEntityFromKey("Product Option Value");

    const router = useRouter();

    const localization = useMemo(() => ({
        new: capitalize(model.getLocalization("new") ?? "New"),
        delete: "Delete",
    }), []);

    const [newRecord, setNewRecord] = useState < { ref: ProductOptionTemplateProductReference, item: Partial<ProductOption>}>();
    const [record, setRecord] = useState<{ ref: ProductOptionTemplateProductReference, item: ProductOptionValue }>();

  



    const { data, isLoading } = queryEntitySWR<ProductOptionTemplateProductReference>(model.getEntityFromKey("Product Option Template Product Reference"),
        new ODataBuilder<ProductOptionTemplateProductReference>()
            .select("id", "productoption", "productoptionid")
            .expand("productoption", po => po.select("id", "name"))
            .filter(`templateproductid eq ${formValues.id}`)
            .build());
     
    const { ribbon, view, attribute, lookup } = useMemo(() => {
        const lookup = entity.attributes["Product"].type as PolyLookupType;
        const form = lookup.forms?.["Main"] as TypeFormDefinition;
        const view = entity.views?.[form?.view!] ?? Object.values(entity.views ?? {})[0];
        return {
            ribbon: {
                ...view.ribbon ?? {},
                'newRelatedItem': {
                    key: "newRelatedItem",
                    text: `${localization.new} ${entity.locale?.[
                        model.locale
                    ]?.displayName ??
                        entity.displayName
                        }`,
                    iconProps: { iconName: "Add" },
                    onClick: (e, i) => {



                        if (view?.ribbon?.new?.supportQuickCreate) {

                            //    setactiveViewRef(gridprops);
                        } else {

                            router.push(model.newEntityUrl(
                                router.query.appname as string,
                                router.query.area as string,
                                entity.logicalName,
                                undefined,
                                {
                                    [attribute]: lookup.split ? `${formValues["$type"]}:${formValues?.id}` : formValues?.id,
                                }
                            ));
                        }
                        //location.href = ;
                    },
                } as ICommandBarItemProps,
                'deleteSelection': {
                    key: "deleteSelection",
                    text: `${localization.delete}`,
                    iconProps: { iconName: "Delete" },
                    disabled: checkedItems.filter(([a, b]) => b === true).length === 0,
                    onClick: (e, i) => {
                        setTimeout(async () => {
                            const deletes = checkedItems.filter(([a, b]) => b === true).map(([a, b]) => a.toString().split(':') as [string, string]);
                            //console.log("deleteSelection", deletes);

                            let tasks = checkedItems.map(([a, b]) => a.toString().split(':') as [string, string]).map(([entity, id]) => deleteRecordSWR(model.getEntity(entity), id));
                            await Promise.all(tasks);

                            location.reload();
                        });
                    },
                } as ICommandBarItemProps,
                'newOptionValue': {
                    key: "newOptionValue",
                    text: `${localization.new} ${optionValueEntity.locale?.[
                        model.locale
                    ]?.displayName ??
                        optionValueEntity.displayName
                        }`,
                    iconProps: { iconName: "Add" },
                    disabled: checkedItems.filter(([a, b]) => b === true).length !== 1,
                    onClick: (e, i) => {
                        console.log("ProductOptionsEditor", [data.items, checkedItems]);
                     var ref=   data.items.find(x => x.id === checkedItems.filter(([a, b]) => b === true)[0][0]?.toString().split(':')[1]);
                        setNewRecord({ ref:ref!, item: {} });
                    },
                } as ICommandBarItemProps,

            },
            view,
            lookup,
            attribute: entity.attributes["Product"].logicalName
        }
    }, [checkedItems, data]);


   
  

    console.log("ProductOptionsEditor", [checkedItems, formValues, data]);
    const styles = useSectionStyles();
    const stackStyles = useStackStyles();
    return (
        <div className={mergeClasses(stackStyles.stack, styles.fullWidth)}>
            <StateTabProvider >
                <RibbonContextProvider >
                    <RibbonHost ribbon={ribbon} >
                        <RibbonBar className={styles.section} hideBack />
                        <div className={mergeClasses(stackStyles.stack, 'horizontal', 'grow')}>
                            <FlatTree checkedItems={checkedItems}
                                onCheckedChange={(ev, checked) => { console.log("ProductOptionsEditor", checked); setCheckedItems(old => [...old.filter(([item, c]) => item !== checked.value), [checked.value, checked.checked]]) }}
                                selectionMode="multiselect" className={mergeClasses(styles.section, stackStyles.tree)}
                                aria-label="Lazy Loading">

                                {/*{Array.from(flatTree.items(), (flatTreeItem) => {*/}
                                {/*    const treeItemProps = flatTreeItem.getTreeItemProps();*/}
                                {/*    const item = treeItemProps.item;*/}
                                {/*    return (*/}
                                {/*        <Subtree treeItemProps={treeItemProps as FlatTreeItemProps }*/}
                                {/*            onSelect={(optionvalue: ProductOptionValue) => setRecord({ ref: item, item: optionvalue })}*/}
                                {/*            productOptionId={item.productoptionid}*/}
                                {/*            value={item.productoption.name} />*/}
                                {/*    );*/}
                                {/*})}*/}

                                {data?.items?.map(item => <Subtree
                                    onSelect={(optionvalue: ProductOptionValue) => setRecord({ ref: item, item: optionvalue })}
                                    productOptionId={item.productoptionid}
                                    value={`${item["$type"]}:${item.id}`} label={item.productoption.name} />)}
                            </FlatTree>

                            {record && !newRecord && <FormRender hideFooter
                                key={record.item.id}
                                formName="Main Information"
                                record={record.item}
                                entityName={model.getEntityFromKey("Product Option Value").logicalName}
                                dismissPanel={(ev) => { console.log("ProductOptionsEditor", ev); }}
                                onChange={(data, ctx) => {
                                    console.log("ProductOptionsEditor data", data);


                                    onChange((old) => {
                                        old["productoptiontemplateproductreferences"] ??= [];
                                        let existing = old["productoptiontemplateproductreferences"].find((p: IRecord) => p.productoptionid === data.productoptionid);
                                        if (!existing) {
                                            existing = {
                                                id: record.ref.id,
                                                productoptionid: data.productoptionid,
                                                productoption: {
                                                    id: data.productoptionid,
                                                    productoptionvalues: []
                                                }
                                            };
                                            old["productoptiontemplateproductreferences"].push(existing)
                                        }

                                        let idx = existing.productoption.productoptionvalues.findIndex((p: IRecord) => p.id === data.id);
                                        if (idx === -1)
                                            existing.productoption.productoptionvalues.push(data);
                                        else {
                                            existing.productoption.productoptionvalues[idx] = data;
                                        }
                                        return old;
                                    });
                                }} />}

                            {newRecord && <FormRender
                                saveBtnText="Opret"
                                formName="Main Information"
                                record={newRecord.item}
                                entityName={optionValueEntity.logicalName}
                                dismissPanel={(ev) => {
                                    console.log("ProductOptionsEditor", ev, newRecord);

                                    onChange((old) => {
                                        old["productoptiontemplateproductreferences"] ??= [];
                                        let existing = old["productoptiontemplateproductreferences"].find((p: IRecord) => p.id === newRecord.ref.id);
                                        if (!existing) {
                                            existing = newRecord.ref;
                                            old["productoptiontemplateproductreferences"].push(existing)
                                        }

                                        existing.productoption.productoptionvalues ??= [];
                                        existing.productoption.productoptionvalues.push(newRecord.item);
                                        
                                        return old;
                                    });

                                }}
                                onChange={(data, ctx) => {
                                    console.log("ProductOptionsEditor data", data);
                                    setNewRecord(old => ({ ...old!, item:data}));
                                }} />}
                        </div>
                    </RibbonHost>


                </RibbonContextProvider>
            </StateTabProvider>

        </div>
    )
}


RegisterControl("ProductOptionsEditor", ProductOptionsEditor)