"use client";
import React, { useState } from "react";
import { makeStyles, shorthands } from "@griffel/react";
import { checkboxInputSchema } from "./CheckboxInputSchema";
import { InputComponentType, quickformtokens, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";

const useCheckboxInputStyles = makeStyles({
    checkboxContainer: {
        display: "flex",
        ...shorthands.margin("10px 0"),
        boxSizing: "border-box",
        alignItems: 'center',
        cursor: 'pointer',
    },
    checkBoxDiv: {
        ...shorthands.border("0.15em", "solid", `${quickformtokens.primary}`),
        ...shorthands.borderRadius("10%"),
        backgroundColor: "#fff",
        height: "1.5em",
        width: "1.5em",
        ...shorthands.padding("2px"),
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    },
    checkboxInput: {
        /* This hides the default implementation of <input type="radio" /> */
        appearance: "none",
        WebkitAppearance: "none",
        backgroundColor: "transparent",
        ...shorthands.margin(0),

        /* This is the custom styling application - kinda confusing it has to be done this way >.< */
        color: quickformtokens.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85%",
        width: "85%",
        cursor: "pointer",

        ":checked": {
            opacity: 1,
            boxShadow: `inset 2em 2em ${quickformtokens.primary}`,
            clipPath: "polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)",
        }
    },
    label: {
        fontSize: quickformtokens.questionParagraphFontSize,
        marginLeft: '10px',
    }
});

export const CheckboxInput: InputComponentType<{}> = ({ questionModel }) => {
    const [isChecked, setIsChecked] = useState<boolean>(questionModel.output === "true");
    const { answerQuestion } = useQuickForm();
    const styles = useCheckboxInputStyles();

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setIsChecked(event.target.checked);
    //     answerQuestion(questionModel.logicalName, event.target.checked ? "true" : "false")
    // }
    const handleChange = () => {
        setIsChecked(!isChecked);
        answerQuestion(questionModel.logicalName, isChecked)
    }

    return (
        <div
            className={styles.checkboxContainer}
            onClick={handleChange}
        >
            <div className={styles.checkBoxDiv}>
                <input
                    type="checkbox"
                    id={"checkbox-input" + questionModel.logicalName}
                    checked={isChecked}
                    onChange={handleChange}
                    className={styles.checkboxInput}
                />
            </div>

            <label
                className={styles.label}
                htmlFor={"checkbox-input" + questionModel.logicalName}
            >
                {questionModel.text}
            </label>
        </div>
    );
};

CheckboxInput.inputSchema = checkboxInputSchema;
registerInputComponent("checkbox", CheckboxInput);