import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useModelDrivenViewContext, useRibbon, useSelectionContext } from "@eavfw/apps";
import { useClientContext, useJsonFetcher } from "@eavfw/manifest";
import { runWorkflow } from "@eavfw/utils";
import {
    Button,
    Dialog, DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from "@fluentui/react-components";
import { Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import Link from "next/link";

/// Reused for both exporting a single template when you are on a specific record, or when exporting a selection when you are on a veiw (looking at the list of templates)
RegistereRibbonButton("CUSTOM_RIBBON_VIEW_EXPORT_SELECTION", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { selection, selectionDetails } = useSelectionContext();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const { mutate } = useModelDrivenViewContext();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isLoading, { toggle: doneLoading }] = useBoolean(true);
    const [downloadLink, setDownloadLink] = useState<string>("");


    useEffect(() => {
        console.log("selectionDetails: ", selectionDetails, props, currentEntityName, currentRecordId);
    },[])

    registerButton({
        key: key,
        showOnCard: true,
        text: props.text,
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text,
        disabled: (currentRecordId === undefined) && !((selection?.getSelectedCount() ?? 2) > 0),   //If currentRecordId is not undefined we are on a record and not in the view
        onClick: () => {
            setTimeout(async () => {
                let wfName = "ExportTemplatesWorkflow";
                toggleHideDialog();

                let { result, rsp } = await runWorkflow(wfName, "Manual", { recordIds: (currentRecordId === undefined) ? selection?.getSelection()?.map(s => s.id) : [currentRecordId] });
                console.log("result: ", result);

                doneLoading();
                setDownloadLink(`${process.env.NEXT_PUBLIC_API_BASE_URL}/files/${result.body}`)
            });
        }
    }, [selectionDetails, downloadLink, isLoading]);
    
    return <Dialog open={!hideDialog}>
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Downloading selection.</DialogTitle>
                <DialogContent>
                    {isLoading && "Please wait..."}
                    {!isLoading && (<div>{"download finished:"} <Link href={downloadLink}>downloadExportFile</Link></div>)}
                </DialogContent>
                <DialogActions fluid>
                    <Button
                        appearance="primary"
                        disabled={isLoading}
                        onClick={() => {
                            mutate();
                            toggleHideDialog();
                            doneLoading();
                        }}>
                        {isLoading ? <Spinner /> : 'Close'}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>


});
