import { FieldProps } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import { VFC, ChangeEvent, useState, useMemo, useEffect } from 'react';
import { RegistereControl, useModelDrivenApp } from '@eavfw/apps';
import { useEAVForm } from '@eavfw/forms';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { UploadResult, UploadWorkerArguments, UploadWorkerEventType, UploadWorkerMessage } from './models';
import { useClientContext } from "@eavfw/manifest";

export type FileUploadControlProps = {
    onChange: FieldProps['onChange']
    value: any
    entityName: string
    fieldName: string
    attributeName: string
    formName: string
    'x-control-props': { schemas: Array<{ uri: string, schema: JSONSchema7 }> }
}

export const FileUploadControl: VFC<FileUploadControlProps> =
    ({
        entityName,
        formName,
        fieldName,
        'x-control-props': { schemas } = {},
        attributeName,
        ...props
    }) => {
        const app = useModelDrivenApp();
        const entityAttributes = app.getAttributes(entityName);
        const attribute = entityAttributes[attributeName];
        const { baseUrl } = useClientContext();

        const [data, { onChange: onFormDataChange }] = useEAVForm<any, any, any>((state) => state.formValues);
        const [percentComplete, setPercentComplete] = useState<number | undefined>(0);
        const [statusDescription, setStatusDescription] = useState('')
        const [fileExists, setFileExists] = useState<boolean>(data[attribute.logicalName]);
        const [fileName, setFileName] = useState(data[attributeName.toLowerCase().replace(" ", "")]?.name ?? "");
        const [fileId, setFileId] = useState(data[attributeName.toLowerCase().replace(" ", "")]?.id ?? "");

        const uploadWorker = useMemo(() => new Worker(new URL('./worker.ts', import.meta.url), { type: 'module', name: 'upload-worker' }), [])

        const handleResetClicked = () => {
            /* Does not currently delete the file uploaded, nor does it set the reference in the entity to null.
             * The reference in the entity is only set if another file is uploaded.
            */
            setFileExists(false);
            setPercentComplete(0);
            setStatusDescription('');
        }

        const handleDownloadClicked = () => {
            window.open(`${baseUrl}/files/${fileId}`, '_blank');
        }

        useEffect(() => {
            return () => uploadWorker.terminate();
        }, [uploadWorker])

        uploadWorker.onmessage = (e: MessageEvent<UploadWorkerMessage>) => {
            const message = e.data

            switch (message.type) {
                case UploadWorkerEventType.Progress:
                    setPercentComplete(message.data)
                    break

                case UploadWorkerEventType.Stage:
                    setStatusDescription(message.data)
                    break

                case UploadWorkerEventType.Error:
                    console.error(message.data)
                    break

                case UploadWorkerEventType.Done:
                    const result: UploadResult = message.data
                    onFormDataChange(props => {
                        props[attribute.logicalName] = result.id;

                    })
                    setFileExists(true);
                    setFileName(result.name);
                    setFileId(result.id);
                    break
            }
        }

        let onChange = async (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0]

            if (file) {
                uploadWorker.postMessage(new UploadWorkerArguments(file, data.id))
            }
        }

        if (fileExists) {
            return (
                <div style={{ marginTop: '5px' }}>
                    <div style={{ marginBottom: '5px', marginTop: '5px' }}>{fileName}</div>
                    <button style={{ marginRight: '5px' }} onClick={handleDownloadClicked}>Download file</button>
                    <button style={{ marginRight: '5px' }} onClick={handleResetClicked}>Reset</button>
                </div>
            )
        }

        return (
            <div style={{ marginTop: '5px' }}>
                <input
                    onChange={onChange}
                    type='file' />
                <ProgressIndicator label='Status' description={statusDescription} percentComplete={percentComplete} />
            </div>
        )
    }

RegistereControl('FileUploadControl', FileUploadControl)