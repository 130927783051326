

import { WidgetProps } from "@rjsf/utils";
import { registerToken } from "@eavfw/quickform-designer/src/Components/Views/QuickFormSettingsView";
import { registerInputControlDesignerWidget } from "@eavfw/quickform-designer/src/Components/Views/QuickFormQuestionsView";
import { useJsonFetcher, useSWRFetch } from "@eavfw/manifest";
import { useComboboxFilter, ComboboxProps, Combobox, makeStyles, Field } from "@fluentui/react-components";
import React, { useId, useMemo, useEffect } from "react";



const useStyles = makeStyles({
    listbox: {
        // maxHeight will be applied only positioning autoSize set.
        maxHeight: "250px",
    },
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        gap: "2px",
        maxWidth: "400px",
        maxHeight: "250px",
    },
});
function onlyUnique<T>(value:T, index:number, array:Array<T>) {
    return array.indexOf(value) === index;
}
function contains(str: string, array: Array<string>) {
    return array.indexOf(str) !== -1
}
const FontFamilyWidget = ({ onChange, value }: WidgetProps) => {


    const { data } = useSWRFetch("/providers/google.fonts/webfonts");
    const items = data?.items ?? [];
    const categories = useMemo(() => {
        return (items).reduce(
            (result: any, currentValue: any) => {
                (result[currentValue['category']] = result[currentValue['category']] || []).push(currentValue);
                return result;
            }, {});
    }, [data]);
    const categoriesOptions = useMemo(() => Object.keys(categories).map(category => ({ children: category, value: category })), [categories]);

    const comboId = useId();
    const styles = useStyles();
    const selectedValue = useMemo(() => items.find((x: any) => x.family === value.split(';')[0]), [items, value]);


    const [categoryQuery, setCategoryQuery] = React.useState<string>(selectedValue?.category ?? "");
   
    const categoryChildren = useComboboxFilter(categoryQuery, categoriesOptions, {
        noOptionsMessage: "No category match your search.",
    });
    const onCategoryOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
        setCategoryQuery(data.optionText ?? "");
    };

   
    const family = useMemo(() => {
        return (data?.items ?? []).filter((font:any) => !categoryQuery || font['category'] === categoryQuery ).reduce(
            (result: any, currentValue: any) => {
                (result[currentValue['family']] = result[currentValue['family']] || []).push(currentValue);
                return result;
            }, {});
    }, [data, categoryQuery]);
    const familyOptions = useMemo(() => Object.keys(family).map(family => ({ children: family, value: family })), [family]);

    const [query, setQuery] = React.useState<string>(value.split(';')[0]??"");
    const children = useComboboxFilter(query, familyOptions, {
        noOptionsMessage: "No fonts match your search.",
    });
    const onOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
        setQuery(data.optionText ?? "");
        let anonymousPro: any = items.find((x: any) => x['family'] === data.optionText);
        if (!anonymousPro)
            return;

        var apiUrl : string[] = [];
        apiUrl.push('https://fonts.googleapis.com/css2?family=');
        apiUrl.push(anonymousPro.family.replace(/ /g, '+'));
        //if (contains('italic', anonymousPro.variants)) {
        //    apiUrl.push(':');
        //    apiUrl.push('italic');
        //}
        //if (contains('greek', anonymousPro.subsets)) {
        //    apiUrl.push('&subset=');
        //    apiUrl.push('greek');
        //}
        var weights = anonymousPro.variants.map((x: any) => Number.parseInt(x)).filter((x: any) => !isNaN( x));
        // url: 'https://fonts.googleapis.com/css?family=Anonymous+Pro:italic&subset=greek'
        if (weights.length>0)
            apiUrl.push(`:wght@${weights.filter(onlyUnique).join(';') }`);


        var url = apiUrl.join('');
        if (value !== `${data.optionText};${url}&display=swap`)
            onChange(`${data.optionText};${url}&display=swap`);
        //
    };

    useEffect(() => {
        setCategoryQuery(selectedValue?.category??"");
        setQuery(selectedValue?.family??"");
    }, [selectedValue])

    return (
        <div className={styles.root}>
            <Field id={comboId} label="Font">
                <Field id={comboId} label="Category">
                    <Combobox
                        onOptionSelect={onCategoryOptionSelect}
                        aria-labelledby={comboId}
                        placeholder="Select an category"
                        onChange={(ev) => setCategoryQuery(ev.target.value)}
                        value={categoryQuery} clearable
                        listbox={{ className: styles.listbox }}
                        defaultValue={selectedValue?.category}
                        defaultSelectedOptions={(selectedValue?.category ? [selectedValue?.category]:[])}
                    >
                        {categoryChildren}
                    </Combobox>
                </Field>
                <Field id={comboId} label="Family">
                    <Combobox
                        onOptionSelect={onOptionSelect}
                        aria-labelledby={comboId}
                        placeholder="Select an fontFamily" clearable
                        onChange={(ev) => setQuery(ev.target.value)}
                        value={query}
                        defaultValue={selectedValue?.family}
                        defaultSelectedOptions={(selectedValue?.family?[ selectedValue?.family]:[])}
                        listbox={{ className: styles.listbox }}
                    >
                        {children}
                    </Combobox>
                </Field>
            </Field>
        </div>
    );

}

registerInputControlDesignerWidget("fontFamily", FontFamilyWidget);
registerToken("fontFamily", "Font Used", "The font used", "fontFamily");
registerToken("questionInputGap", "Question Input Gap", "The gap between paragraph and input component", "text");
registerToken("questionHeadlineFontWeight", "Question Headline Font Weight", "The font-weight of questions", "text");

