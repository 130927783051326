
import {
    Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, InfoLabel, Input, Table, TableBody,
    TableCell, TableCellLayout, TableColumnDefinition, TableColumnSizingOptions, TableHeader,
    TableHeaderCell, TableRow, TableSelectionCell, Toolbar, ToolbarButton, ToolbarDivider, Tooltip, createTableColumn, makeStyles, shorthands, useTableColumnSizing_unstable, useTableFeatures, useTableSelection
} from "@fluentui/react-components";
import { FieldProps, getUiOptions } from "@rjsf/utils";
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";


import { registerInputControlDesignerField } from "@eavfw/quickform-designer";
import { Toggle } from "@fluentui/react";
import { useLoadScript } from "@react-google-maps/api";
import React from "react";
import { DawaAddressInput } from "./UIHelpers/Dawa/DawaAddressInputDesigner";

type Item = {
    key: string;
    title: string;
    location: string,
    isUserInput: boolean;
}
type ItemDict = { [key: string]: Item };

const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
        columnId: "title",
    }),
    createTableColumn<Item>({
        columnId: "location",
    }),
    createTableColumn<Item>({
        columnId: "isUserInput",
    }),
    createTableColumn<Item>({
        columnId: "keyboardkey",
    }),
];

const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    toolbar: {
        justifyContent: "end",
        ...shorthands.gap("8px")
    },
});

type Library = 'places';
const libraries: Library[] = ['places'];

export const GoogleDistanceField: React.FC<PropsWithChildren<FieldProps<ItemDict>>> = ({ uiSchema = {}, schema: rawSchema, required, formData, registry, title, name, onChange, ...props }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
        libraries,
    });

    const styles = useStyles();
    const { fields, formContext, schemaUtils, translateString, globalUiOptions } = registry;
    const schema = schemaUtils.retrieveSchema(rawSchema, formData);

    const uiOptions = getUiOptions(uiSchema, globalUiOptions);

    const items = Object.entries(formData ?? {}).map(([k, e]) => { return { key: k, title: e.title, location: e.location, isUserInput: e.isUserInput } }) as Item[];
    const [columnSizingOptions] = useState<TableColumnSizingOptions>({
        title: {
            idealWidth: 50,
        },
        location: {
            idealWidth: 150,
        },
        isUserInput: {
            defaultWidth: 50,
        },
    });

    const {
        getRows,
        columnSizing_unstable,
        tableRef,
        selection: {
            clearRows,
            allRowsSelected,
            someRowsSelected,
            toggleAllRows,
            toggleRow,
            isRowSelected,
            selectedRows
        },
    } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableColumnSizing_unstable({ columnSizingOptions }),
            useTableSelection({
                selectionMode: "multiselect",
                defaultSelectedItems: new Set(),
            }),

        ],

    );
    const toggleAllKeydown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === " ") {
                toggleAllRows(e);
                e.preventDefault();
            }
        },
        [toggleAllRows]
    );
    const rows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === " ") {
                    e.preventDefault();
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
        };
    });

    const [open, setOpen] = useState(false);
    const [optionValue, setOptionValue] = useState<Item>({ key: '', title: '', location: '', isUserInput: false });

    const optionsSchema = rawSchema.additionalProperties;
    console.log("here optionsSchema", optionsSchema);
    const customOptionSchema = optionsSchema !== true

    const handleSubmit = () => {
        if (optionsSchema !== true) {
            console.log("optionValue", [optionValue, { ...formData, [optionValue.key]: { ...optionValue } }]);
            onChange({ ...formData, [optionValue.key]: { ...optionValue } });
            //onChange({ ...formData, [entityKey]: { location: optionLocation, isUserInput: optionIsUserInput } });
        }

        setOpen(false);
    }

    let autocompleteRef = useRef<google.maps.places.Autocomplete>();
    const inputRef = useRef<HTMLInputElement>(null);

    const handlePlaceSelected = () => {
        const place = autocompleteRef.current?.getPlace();


        if (place) {
            const address = place.formatted_address;
            setOptionValue({ ...optionValue, location: address ?? "" })
        }
    };

    const GetNewGreatestIndex = () => {

        if (!formData)
            return 0;

        const formdataList = Object.values(formData);
        if (formdataList.length == 0) return 0;

        const currentGreatestIndex = Math.max(...formdataList.map(i => Number(i.key)));
        return currentGreatestIndex + 1;
    }

    useEffect(() => {
        if (isLoaded && inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(
                inputRef.current,
                { types: ["address"] }
            );

            autocompleteRef.current.addListener("place_changed", handlePlaceSelected);
        }

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            if (autocompleteRef.current) {
                google.maps.event.clearInstanceListeners(autocompleteRef.current);
            }
        };
    }, [isLoaded, handlePlaceSelected]);
    return (<>
        <Dialog
            open={open}
            onOpenChange={(event, data) => {
                // it is the users responsibility to react accordingly to the open state change
                setOpen(data.open);
            }}>

            <DialogSurface aria-describedby={undefined} mountNode={undefined}>
                <DialogBody>
                    <DialogTitle>New Address</DialogTitle>
                    <DialogContent className={styles.content}>
                        {typeof (optionsSchema) !== "boolean" && optionsSchema &&
                            <>
                                <Field label="Title">
                                    <Input value={optionValue.title} required type="text" id={"question-schema-name"} onChange={(e: any, d: any) => setOptionValue({ ...optionValue, title: d.value })} />
                                </Field>
                                <Field
                                    //@ts-ignore
                                    label=
                                    {{
                                        children: (_: unknown, props: any) => (
                                            <InfoLabel {...props} info="Let the user select the location">
                                                IsUserInput
                                            </InfoLabel>
                                        ),
                                    }}
                                >
                                    <Toggle checked={optionValue.isUserInput} id={"question-schema-name"} onChange={(e, d) => setOptionValue({ ...optionValue, isUserInput: d! })} />
                                </Field>
                                <div>
                                    {
                                        !optionValue.isUserInput &&
                                        <Field label="Location">
                                            {/*<Input ref={inputRef} value={optionValue.location} required type="text" id={"question-schema-name"} onChange={(e: any, d: any) => setOptionValue({ ...optionValue, location: d.value })} />*/}
                                            <DawaAddressInput location={optionValue.location} isLoaded={isLoaded} handleLocationSet={(location: string) => { setOptionValue({ ...optionValue, location: location }) }} />
                                        </Field>
                                    }
                                </div>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <Button type="submit" appearance="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </DialogBody>

            </DialogSurface>
        </Dialog >
        <Toolbar aria-label="Table Toolbar" size="small" className={styles.toolbar}>
            <ToolbarDivider />
            {selectedRows.size === 2 && <Tooltip
                content="Swap options"
                relationship="description"
                withArrow
            >
                <ToolbarButton
                    appearance="primary"
                    onClick={() => {
                        let [a, b] = rows.filter(x => x.selected).map(r => { return Number(r.rowId) });

                        const entries = Object.entries(formData as ItemDict);

                        //Swap entries
                        const temp = entries[a][1];
                        entries[a][1] = entries[b][1];
                        entries[a][1] = entries[b][1];
                        entries[b][1] = temp;

                        //Swap object property keys 
                        const tempKey = entries[a][1].key;
                        entries[a][1].key = entries[b][1].key;
                        entries[b][1].key = tempKey;

                        onChange(Object.fromEntries(entries));
                    }}>
                    Swap
                </ToolbarButton>
            </Tooltip>}
            {selectedRows.size === 0 &&
                <Tooltip
                    content={'Add another option'}
                    relationship="description"
                    withArrow
                >
                    <ToolbarButton
                        appearance="primary"
                        onClick={() => {
                            console.log("optionValue", formData);
                            const index = GetNewGreatestIndex();
                            console.log("optionValue", formData);
                            setOptionValue({
                                key: index.toString(), title: '', location: '', isUserInput: false
                            });
                            setOpen(true);
                        }}>
                        {'Add'}
                    </ToolbarButton>
                </Tooltip>
            }
            {selectedRows.size === 1 &&
                <Tooltip
                    content={'Edit existing'}
                    relationship="description"
                    withArrow
                >
                    <ToolbarButton
                        appearance="primary"
                        onClick={() => {
                            const rowItem = rows.find(x => x.selected)?.item ?? { key: '', title: '', location: '', isUserInput: false };
                            setOptionValue({ key: rowItem.key, title: rowItem.title, location: rowItem.location, isUserInput: rowItem.isUserInput })
                            setOpen(true);
                        }}>
                        {'Edit'}
                    </ToolbarButton>
                </Tooltip>
            }
            {selectedRows.size >= 1 && <Tooltip
                content="Swap options"
                relationship="description"
                withArrow
            >
                <ToolbarButton
                    appearance="primary"
                    onClick={(e) => {
                        let rowsToInclude = rows.filter(x => x.selected === false);
                        onChange(Object.fromEntries(Object.entries(rowsToInclude.map(r => r.item))));

                        clearRows(e)
                    }}>
                    Delete
                </ToolbarButton>
            </Tooltip>}

        </Toolbar>
        <Table aria-label="Table with multiselect" size="small" ref={tableRef}>
            <TableHeader>
                <TableRow>
                    <TableSelectionCell
                        checked={
                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                        }
                        onClick={toggleAllRows}
                        onKeyDown={toggleAllKeydown}
                        checkboxIndicator={{ "aria-label": "Select all rows " }}

                    />

                    <TableHeaderCell {...columnSizing_unstable.getTableHeaderCellProps('title')}>Titel</TableHeaderCell>
                    <TableHeaderCell {...columnSizing_unstable.getTableHeaderCellProps('location')}>Location</TableHeaderCell>
                    <TableHeaderCell {...columnSizing_unstable.getTableHeaderCellProps('isUserInput')}>Bestemt af brugeren</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {rows.map(({ item, selected, onClick, onKeyDown, appearance }, i) => (
                    <TableRow
                        key={i}
                        onKeyDown={onKeyDown}
                        aria-selected={selected}
                        appearance={appearance}
                    >
                        <TableSelectionCell
                            checked={selected}
                            checkboxIndicator={{ "aria-label": "Select row" }}
                            onClick={onClick}
                        />
                        <TableCell>
                            <TableCellLayout>
                                <div>{item.title}</div>
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>
                            <TableCellLayout>
                                <div style={{ opacity: item.isUserInput ? "0.3" : "1" }}>{item.location ? item.location : "Chosen by the user"}</div>
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>
                            <TableCellLayout>
                                <Toggle checked={item.isUserInput} id={"question-schema-name"} onChange={(e, d) => onChange({ ...formData, [i]: { ...item, isUserInput: d! } })} />
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </>);
};

registerInputControlDesignerField("GoogleDistanceField", GoogleDistanceField);