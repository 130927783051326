import {
    CompositePropagator,
    W3CBaggagePropagator,
    W3CTraceContextPropagator,
} from '@opentelemetry/core';
import { BatchSpanProcessor, ConsoleSpanExporter, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
//import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
//import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { DiagConsoleLogger, DiagLogLevel, Span, diag } from '@opentelemetry/api';
//import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { Resource } from '@opentelemetry/resources';
import { SEMRESATTRS_SERVICE_NAME, SEMRESATTRS_SERVICE_NAMESPACE } from '@opentelemetry/semantic-conventions';
//import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-grpc";
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';

diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.INFO);

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


const FrontendTracer = async () => {
    console.log("APP SCRIPT LOADING", [getCookie("traceparent")]);

    var meta = document.createElement('meta');
    meta.name = "traceparent";
    meta.content = getCookie("traceparent")
    document.getElementsByTagName('head')[0].appendChild(meta);

   
    const { ZoneContextManager } = await import('@opentelemetry/context-zone');

    const provider = new WebTracerProvider({
        resource: new Resource({
            [SEMRESATTRS_SERVICE_NAME]: 'go-qp-management-front',
            [SEMRESATTRS_SERVICE_NAMESPACE]: "go-qp"
        }),
    });

    const exporter = new ConsoleSpanExporter();
   

    const processor = new BatchSpanProcessor(exporter);
    provider.addSpanProcessor(processor);

    const OTLPexporter = new OTLPTraceExporter({ url: `${process.env['NEXT_PUBLIC_BASE_URL']??''}otel/trace` });
    provider.addSpanProcessor(
        new BatchSpanProcessor(OTLPexporter, {
            // bufferTimeout: 15000,
            //    bufferSize: 1000
        })
    );

    const contextManager = new ZoneContextManager();

    provider.register({
        contextManager,
        propagator: new CompositePropagator({
            propagators: [
                new W3CBaggagePropagator(),
                new W3CTraceContextPropagator(),
            ],
        }),
    });

    const addCustomAttributesToSpan = (span: Span) => {
       
     //   span.setAttribute('<custom.attribute.key>', '<custom-attribute-value>');
    }

   

    const addCustomAttributesToResourceFetchSpan = (span: Span, resource: PerformanceResourceTiming) => {
        console.log("Resource Fetch", [span, resource]);
        span.updateName(resource.name);

      //  span.spanContext().traceId = getCookie("traceparent").split("-")[1];

       // span.setAttribute('<custom.attribute.key>', '<custom-attribute-value>');
      //  span.setAttribute('resource.tcp.duration_ms', resource.connectEnd - resource.connectStart);
    }

    registerInstrumentations({
        tracerProvider: provider,
        instrumentations: [
            new FetchInstrumentation({
                propagateTraceHeaderCorsUrls: /.*/,
                clearTimingResources: true,
                applyCustomAttributesOnSpan(span) {
                    span.setAttribute('app.synthetic_request', 'false');
                },

            }),
            new DocumentLoadInstrumentation({
                applyCustomAttributesOnSpan: {
                    documentLoad: addCustomAttributesToSpan,
                    resourceFetch: addCustomAttributesToResourceFetchSpan
                }
            }),
            new XMLHttpRequestInstrumentation({
               // ignoreUrls: [/localhost/],
               // propagateTraceHeaderCorsUrls: [
               //     'http://localhost:8090',
               // ],
            }),
            //getWebAutoInstrumentations({
            //    '@opentelemetry/instrumentation-fetch': {
            //        propagateTraceHeaderCorsUrls: /.*/,
            //        clearTimingResources: true,
            //        applyCustomAttributesOnSpan(span) {
            //            span.setAttribute('app.synthetic_request', 'false');
            //        },

            //    },
            //}),
        ],
    });
};

export default FrontendTracer;