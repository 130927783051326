import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@griffel/react';
import { Tooltip } from '@/components/tooltip/Tooltip';

type ActionButtonProps = {
    label: string;
    onClick: () => void;
}

const useActionButtonStyles = makeStyles({
    actionButtonContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    item: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    actionIcon: {
        backgroundColor: 'rgb(41,61,94)',
        padding: '10px',
        borderRadius: '50px',
        cursor: 'pointer',
        border: 'none',
        marginBottom: '5px',

        alignItems: 'center',
        '&:hover': {
            opacity: 0.8
        }
        , '&:active': {
            opacity: 0.6
        }
        , '&:focus': {
            outline: 'none'
        },
        '&:focus-visible': {
            outline: '2px solid #000'
        },
    }
});

export const ActionButton: React.FC<PropsWithChildren<ActionButtonProps>> = ({ onClick, label, children }) => {

    const classes = useActionButtonStyles();

    return (
        <div className={classes.actionButtonContainer}>
            <div className={classes.item}>
                <Tooltip label={label}>
                    <button className={classes.actionIcon} onClick={onClick}>
                        {children}
                    </button>
                </Tooltip>
            </div>
            <div className={classes.item}>
                {label}
            </div>
        </div>
    );
};