import React from 'react';
import { makeStyles, mergeClasses } from "@griffel/react";
import { Tooltip } from '@/components/tooltip/Tooltip';
import { ButtonProps } from './buttonProps';
import { DashboardLocaleDK } from '../dashboard/locale/DashboardLocaleDK';

const useBackButtonStyles = makeStyles({
    button: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8
        }
        , '&:active': {
            opacity: 0.6
        }
        , '&:focus': {
            outline: 'none'
        },
        '&:focus-visible': {
            outline: '2px solid #000'
        },

    }
});

export const BackButton: React.FC<ButtonProps> = ({ size = 24, color = "black", onClick, style, className }) => {
    const styles = useBackButtonStyles();
    return (
        <Tooltip label={DashboardLocaleDK.goBack} direction='right'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={mergeClasses(styles.button, className)}
                style={style}
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill={color}
                onClick={onClick}
            >
                <g data-name="Layer 2">
                    <g data-name="arrow-back">
                        <rect
                            width={size}
                            height={size}
                            transform="rotate(90 12 12)"
                            opacity="0"
                        />
                        <path
                            d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    );
};