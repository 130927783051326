import { ValidationResult } from "@eavfw/quickform-core/src/model/ValidationResult";
import { NumberProperties } from "./NumberInput";
import { QuestionModel } from "@eavfw/quickform-core/src/model/QuestionModel";
import { QuickformState } from "@eavfw/quickform-core";

export const validateNumberInput = (output: any, properties: NumberProperties, model: QuestionModel, state: QuickformState): Promise<ValidationResult> => {
    

    return new Promise((resolve) => {

        if (typeof properties.min === "undefined" && typeof properties.max === "undefined") {
            return resolve({ isValid: true, message: '', validatedOutput: output });
        }

       const valid = typeof output === 'number' && output >= properties.min && output <= properties.max;
       resolve({
           isValid: valid,
           message: valid ? "" : `Værdien skal være mellem ${properties.min} og ${properties.max}.`,
           validatedOutput: output,
       });
   });
};
