import { useEAVForm } from "@eavfw/forms";
import { registerLineGeneratorField } from "../QuotationLineManager";
import { useId, useMemo, useState } from "react";
import { ungzip } from "pako";
import { QuickFormDefinition } from "@eavfw/quickform-core";
import { Combobox, ComboboxProps, Field, useComboboxFilter } from "@fluentui/react-components";
import { Field as RJSFField, getUiOptions } from "@rjsf/utils";
import { useAppInfo, useEAVApp, useModelDrivenApp } from "@eavfw/apps";
import { getRecordSWR, queryEntitySWR } from "@eavfw/manifest";
import { registerInputControlDesignerField } from "@eavfw/quickform-designer";


const useQuickFormDefinition = () => {


    const { currentEntityName } = useAppInfo();

    if (currentEntityName === "quotationlinetemplategeneratorassosiation") {
        const app = useModelDrivenApp();
        const [{ quickformdefinitionid }] = useEAVForm((state) => ({ quickformdefinitionid: state.formValues.quotationformtemplate.quickformdefinitionid }));
        const { record } = getRecordSWR(app.getEntityFromKey("Document").collectionSchemaName, quickformdefinitionid, undefined, !!quickformdefinitionid, {});

        return record;
    }

   
    const [quickFormdefinition] =   useEAVForm((state) => state.formValues.quickformdefinition);

  

    return quickFormdefinition;
}

/**
 * Can be used on Quotation Line Manager component on quotation template / form where quickform is avaible.
 * @param param0
 * @returns
 */
export const QuestionLookupField: RJSFField = ({ onChange, formData, schema: { title }, uiSchema, registry: { globalUiOptions } }) => {
   
    const uiOptions = getUiOptions(uiSchema, globalUiOptions);
     
    const quickFormdefinition = useQuickFormDefinition();
   

    const options = useMemo(() => {
        if (!quickFormdefinition?.data)
            return [];

        const qf = JSON.parse(ungzip(new Uint8Array(atob(quickFormdefinition.data as string).split("").map(function (c) {
            return c.charCodeAt(0);
        })), { to: "string" }) as string) as QuickFormDefinition;


        return Object.entries(qf.questions).filter(([qkey, q]) => !uiOptions["x-input-comp-filter"] || q.inputType === uiOptions["x-input-comp-filter"]).map(([questionKey, question]) => ({
            children: question.text,
            value: questionKey,

        })) ?? [];

    }, [quickFormdefinition]);
 
    const [query, setQuery] = useState<string>();

    const children = useComboboxFilter(query ?? "", options, {
        noOptionsMessage: "No question match your search.",
        optionToText: (option) => option.children
    });

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (e, data) => {
        onChange(data.optionValue);
        setQuery(data.optionText ?? "");

    };

    const comboId = useId();

    return (<Field label={title}>
        <Combobox
            onOptionSelect={onOptionSelect}
            aria-labelledby={comboId}
            placeholder="Select a product"
            defaultSelectedOptions={[formData]}
            defaultValue={options.find(x => x.value === formData)?.children}
            onChange={(ev) => setQuery(ev.target.value)}

            value={query ?? options.find(x => x.value === formData)?.children}
        >
            {children}
        </Combobox>
    </Field>)
}

registerLineGeneratorField("QuestionLookupField", QuestionLookupField);
registerInputControlDesignerField("QuestionLookupField", QuestionLookupField);