
import { RegistereControl } from "@eavfw/apps"
import { makeStyles, shorthands } from "@griffel/react";
import { useEffect, useState } from "react";
import { LeadNavbar } from "./components/LeadNavbar";
import { useSectionStyles } from "@eavfw/apps/src/Styles/SectionStyles.styles";
import { LeadKanbanBoard } from "./components/LeadKanbanBoard";
import { DashboardLocaleDK } from "./locale/DashboardLocaleDK";
import { IRecord, queryEntitySWR } from "@eavfw/manifest";
import { useModelDrivenApp } from "@eavfw/apps"

const useStyles = makeStyles({
    dashboardContainer: {
        height:"100%",
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow:'1',
        flexWrap: 'wrap',
        columnGap: '16px',
        rowGap: '36px',
        ...shorthands.margin("0.285714rem"),
    },
});

function transformData(data: IRecord[]): { key: string, value: string }[] {
    return data.map((item: IRecord) => ({
        key: item.name ?? "",
        value: item.id
    }));
}

function Container(text: string, containerClassName: string, sectionClassName: string) {
    return <div className={containerClassName} >
        <div className={sectionClassName} style={{ textAlign: "center" }}>
            {text}
        </div>
    </div>
}

export const QuotationLeadsDashboard: React.FC = () => {
    const classes = useStyles();
    const eavClasses = useSectionStyles();
    const app = useModelDrivenApp();
    const [boardId, setBoardId] = useState<string>("");
    const [options, setOptions] = useState<Record<string, string>[]>([{ key: "" }]);
    const { data, isError, isLoading, mutate } = queryEntitySWR(app.getEntityFromKey("Board"));

    useEffect(() => {
        if (data?.items) {
            setOptions(transformData(data.items));
            setBoardId(data.items[0]?.id ?? "");
        }
    }, [data]);

    const onChangeBoard = (id: string) => setBoardId(id);;

    if (isLoading) return Container(DashboardLocaleDK.loading + "...", classes.dashboardContainer, eavClasses.section);
    if (data?.items.length === 0) return Container(DashboardLocaleDK.noData, classes.dashboardContainer, eavClasses.section);
    if (data?.items[0].id === undefined) return Container(DashboardLocaleDK.noBoardId, classes.dashboardContainer, eavClasses.section);

    return (
        <div className={classes.dashboardContainer} >
            <div className={eavClasses.section}>
                <LeadNavbar
                    setBoardId={onChangeBoard}
                    boardId={boardId}
                    options={options}

                />
            </div>
            <div className={classes.bodyContainer}>
                <LeadKanbanBoard key={boardId + 2} boardId={boardId} />
            </div>
        </div>
    );
};

RegistereControl("QuotationLeadsDashboard", QuotationLeadsDashboard);